import React, { useRef, useState, useEffect, useContext } from 'react'
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { formatDate } from '../Utils/utils'

export default function Portal() {
    let [ searchParams ] = useSearchParams();
    const id = searchParams.get("id");
    const [state, setState] = useState({id: '2'})
    const [original, setOriginal] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    // DOM References
    const refCustomerNumber = useRef();
    const refName = useRef();
    const refEpic = useRef();
    const refReturnTop = useRef();
    const refSubmit = useRef();
    const refCustomerNumberError = useRef();
    const refNameError = useRef();
    const refEpicError = useRef();
    const refPlatform = useRef();
    const refPlatformError = useRef();
    const refStatus = useRef();
    const refStatusError = useRef();
    const refOwner = useRef();
    const refOwnerError = useRef();
    
    // Heading Modal References
    const overlay = useRef();
    const popup = useRef();
    const modalHeading = useRef();
    const modalContent = useRef();
    const modalClose = useRef();
    
    // Confirm Modal References
    const popupConfirm = useRef();
    const popupConfirmHeading = useRef();
    const popupConfirmContent = useRef();
    
    const popupDeleteConfirm = useRef();

    async function deleteItem(e){
        let id = e.target.parentNode.getAttribute('data-id')
        let newValue = state.owner.split(',').filter(item => item != id).join(',');

        setState(prevState => ({
            ...prevState,
            ['owner']: newValue
        }));    
    }

    async function addItem(e){
        refOwnerError.current.style.display = 'none';
        
        //let value = e.target.parentNode.getAttribute('data-id')
        let value = refOwner.current.value;
        
        if(value === '' || value === null){
            refOwnerError.current.innerHTML = 'Please enter an owner value.'
            refOwnerError.current.style.display = 'block';
            return;
        }

        let split = state.owner.split(',');
        if(split[0] === '') split.length = 0;

        let toAdd = true;
        for(let a=0;a<split.length;a++){
            if(split[a] === value){
                refOwnerError.current.innerHTML = 'Owner value already exists.'
                refOwnerError.current.style.display = 'block';
                return;
            }
        }

        // Run if function did not return with an error
        split.push(value)
        refOwner.current.value = '';
        refOwnerError.current.style.display = 'none';
        
        split = await split.sort((a, b) => {
            a = a.toLowerCase()
            b = b.toLowerCase()
            if (a < b) return -1;
            if(a > b) return 1;
            return 0;
        })
        setState(prevState => ({
            ...prevState,
            ['owner']: split.join(',')
        }));    
    }

    async function keyUp(e){
        if(e.key === 'Enter'){
            addItem()
        }
    }

    // Handler for all input and textarea elements
    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            //[name]: value ? value : null
            [name]: value
        }));
    };

    async function handleDelete(){
        if(!confirmDelete){
            deleteOverlay();
        }else{
            const requestOptions = {
                method: 'DELETE',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
            };
    
            let response = await fetch('https://picform.lahlouhonline.com/api/clients/' + id, requestOptions);

            if(response.status === 403){
                infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
            }else{
                let data = await response.json()
                if(data.message){
                    setTimeout(() => {
                        infoModal('Deleted', 'Portal has been successfully deleted', true);
                    }, 150);
                    setTimeout(() => {
                        navigate(-1);
                    }, 2100);
                }else{
                    setTimeout(() => {
                        infoModal('Error', 'Portal was unable to be deleted', true);
                    }, 150);
                }
            }
        }
    }

    // Delete Modal
    async function deleteOverlay(){
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        overlay.current.className = 'show';
        popupDeleteConfirm.current.className = 'show';
    }

    async function handleSubmit(){
        let shouldSubmit = true;

        refCustomerNumber.current.classList.remove('error-outline');
        refCustomerNumberError.current.style.display='none';
        refName.current.classList.remove('error-outline');
        refNameError.current.style.display='none';
        refEpic.current.classList.remove('error-outline');
        refEpicError.current.style.display='none';
        refPlatform.current.classList.remove('error-outline');
        refPlatformError.current.style.display='none';
        refStatus.current.classList.remove('error-outline');
        refStatusError.current.style.display='none';

        // Company Name Validation
        if(state.name === ''){
            refNameError.current.innerHTML = 'Please enter a company name.';
            refName.current.classList.add('error-outline');
            refNameError.current.style.display='block';
            shouldSubmit = false;
        }

        // EPIC Validation
        if(state.epic === ''){
            refEpicError.current.innerHTML = 'Please enter an associated JIRA EPIC.';
            refEpic.current.classList.add('error-outline');
            refEpicError.current.style.display='block';
            shouldSubmit = false;
        }

        // Platform Validation
        if(state.platform === '' || state.platform.indexOf('Select') !== -1){
            refPlatform.current.classList.add('error-outline');
            refPlatformError.current.style.display='block';
            shouldSubmit = false;
        }

         // Status Validation
         if(state.active.toString() === '' || state.active.toString().indexOf('Select') !== -1){
            refStatus.current.classList.add('error-outline');
            refStatusError.current.style.display='block';
            shouldSubmit = false;
        }

        // Customer Number Validation
        if(state.customer_number === ''){
            refCustomerNumberError.current.innerHTML = 'Please enter a customer number.';
            refCustomerNumber.current.classList.add('error-outline');
            refCustomerNumberError.current.style.display='block';
            shouldSubmit = false;
        }
        else if(!Number.isInteger(parseInt(state.customer_number))){
            refCustomerNumberError.current.innerHTML = 'Customer number must be numeric.';
            refCustomerNumber.current.classList.add('error-outline');
            refCustomerNumberError.current.style.display='block';
            shouldSubmit = false;
        }
        
        let response;
        let data;
        
        if(shouldSubmit){
            // Data is different - show Modal
            if(JSON.stringify(original) !== JSON.stringify(state)){
                if(!confirm) confirmOverlay();
            // Data is the same
            }else{
                infoModal('No changes detected', 'Portal data cannot be updated unless changes are made to the form.')
            }
            
            // Data is different - Confirmed and ready to submit
            if(confirm){
                let requestObj = {
                    customer_number: state.customer_number,
                    name: state.name,
                    epic: state.epic,
                    platform: state.platform,
                    active: state.active,
                    owner: state.owner
                }

                let requestOptions = {
                    method: 'PATCH',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(requestObj)
                };
                response = await fetch('https://picform.lahlouhonline.com/api/clients/' + id, requestOptions)
                
                if(response.status === 403){
                    infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
                    //navigate(0)
                }else{
                    data = await response.json()
                    if(data.name){
                        setTimeout(() => {
                            infoModal('Updated', 'Portal has been successfully updated', true);
                        }, 150);
                        setConfirm(false)
                        setOriginal(state)
                        if(state.customer_number !== id){
                            navigate('/portal?id=' + state.customer_number)
                        }
                    }else{
                        setTimeout(() => {
                            infoModal('Error', 'Portal was unable to be updated. Please try again shortly', true);
                        }, 150);
                    }
                }
            }
        }
    }

    function nullToString(obj){
        for(let i=0;i<Object.values(obj).length;i++){
            if(Object.values(obj)[i] === null){
            obj[Object.keys(obj)[i]] = ''
            }
        }
        return obj;
    }

    // Modal for general dialogues
    async function confirmOverlay(){
        let text = '<div class="center mt10">The following items have changed from their original value:</div>';
        
        for(let i=0;i<Object.values(original).length;i++){
          if(Object.values(original)[i] !== Object.values(state)[i]){
            text += `<div class="changed-wrapper mt5">`
            text += `<div><strong>${Object.keys(original)[i]}:</strong></div>`
            if(!Object.values(original)[i]){
              text += `<div>empty `
            }else{
              text += `<div>${Object.values(original)[i]}`
            }
            text += ` <span>&#129054;</span> ${Object.values(state)[i]}</div></div>`;
          }
        }
        
        // Change from the internal object property names to a nicer display version
        text = text
        .replace('customer_number', 'Customer #')
        .replace('owner', 'Owner')
        .replace('name', 'Company Name')
        .replace('active', 'Status')
        .replace('epic', 'JIRA EPIC')
        // .replace(' 0', ' Inactive')
        // .replace('0 ', 'Inactive ')
        // .replace(' 1', ' Active')
        // .replace('1 ', 'Active ')
    
        text += '</div>'
        text += '<div class="mt10 center">OK to submit with the above changes?</div>';
        
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
       
        popupConfirmContent.current.innerHTML = text;
        overlay.current.className = 'show';
        popupConfirm.current.className = 'show';
    }

    // Handle the submission of the form when user is OK with the changes (custom confirm dialogue)
    useEffect(() => {
        closePopup();
        if(confirm){
          handleSubmit();
        }
    }, [confirm])

    // Handle the submission of the form when user is OK with the changes (custom confirm dialogue)
    useEffect(() => {
        closePopup();
        if(confirmDelete){
          handleDelete();
        }
    }, [confirmDelete])


    // Pull in MySQL Data - Only once on page load
    useEffect(() => {
        closePopup();
        // Verify JWT Token, and then pull data
        const verifyUser = async () => {
          const requestOptions = {
              method: 'POST',
              credentials: 'include',
          };
  
          let response = await fetch('https://picform.lahlouhonline.com/api/verify', requestOptions);
          let data = await response.json()
          if(data.username){
            const requestOptions = {
              method: 'GET',
              credentials: 'include',
              headers: { 'Content-Type': 'application/json' },
            };
  
            fetch('https://picform.lahlouhonline.com/api/client/' + id, requestOptions)
              .then(response => response.text())
              .then(data => {
                // String with '[]' to start, longer means there is data
                if(data.length > 2){
                    setState(nullToString(JSON.parse(data)))
                    setOriginal(nullToString(JSON.parse(data)))
                }
              })
              .catch(error => {
                console.log(error)
              })
          }else{
            await setUser(null)
          }
        }
  
        verifyUser();
    }, [])

    // Informational Modal
    function infoModal(heading, content, autoclose = false){
        modalHeading.current.innerHTML = heading;
        modalContent.current.innerHTML = content;
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        overlay.current.className = 'show';
        popup.current.className = 'show';
        if(autoclose){
            modalClose.current.style.display = 'none';
            setTimeout(() => {
                document.body.style.overflow = "auto";
                document.body.style.marginRight = '0';
                overlay.current.className = '';
                popup.current.className = '';
            }, 2000)
        }else{
            modalClose.current.style.display = 'block';
        }
    }
    
    // Modal functionality for label headings
    function showModal(e){
        e.preventDefault();
        modalHeading.current.innerHTML = e.target.innerHTML;
        modalContent.current.innerHTML = e.target.title;
        
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';

        overlay.current.className = 'show';
        popup.current.className = 'show';
    }

    function closePopup(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(overlay.current) overlay.current.className = '';
        if(popup.current) popup.current.className = '';
        if(popupConfirm.current) popupConfirm.current.className = '';
        if(popupDeleteConfirm.current) popupDeleteConfirm.current.className = '';
    }
    
    //Close popup by clicking anywhere outside of it
    window.onclick = function(event) {
        if (event.target.id == 'overlay') {
            document.body.style.overflow = "auto";
            document.body.style.marginRight = '0';
            if(overlay.current) overlay.current.className = '';
            if(popup.current) popup.current.className = '';
            if(popupConfirm.current) popupConfirm.current.className = '';
            if(popupDeleteConfirm.current) popupDeleteConfirm.current.className = '';
        }
    }

    // Back to top scroll function
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "block";
            }
        } else {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "none";
            }
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    if(state === null || (user && user.role != 'admin')){
        return (
          <div className="main">
            <div className="main-content">
                
                {state === null
                ?
                <>
                <h1 className="mt10">Portal Details</h1>
                <div className="mt15 center"><h3 style={{fontWeight:'400'}}>No portal to display.</h3></div>
                </>
                :
                <div className="mt15 center"><h3 style={{fontWeight:'400'}}>Unauthorized Access</h3></div>
                }
            </div>
          </div>
        )
    } 
    else{
        return (
        <>
        <div className="main">
            <div className="main-content">
                <h1 className="mt10">Portal Details</h1>
    
                <div className="mt20 center">
                    Hover or click on a heading to see additional information when available.
                </div>

                <div className="request-header slate-bg mt20" style={{height:'63px'}}>
                    {/* Back to All Portals Arrow */}
                    <div className="back-to-users">
                        <Link to="/portals" title="Back to all portals" className="left-angle" />
                    </div>
                </div>
                
                <div className="form-bottom">
                    
                    {/* Customer Number */}
                    <div className="element">
                        <label className="required" title="Should match PACE Customer #" onClick={event => showModal(event)}>Customer Number</label>
                        <input
                            type="text"
                            ref={refCustomerNumber}
                            name="customer_number"
                            value={state.customer_number ? state.customer_number : ''}
                            onChange={handleChange}
                        />
                        <div ref={refCustomerNumberError} className="errorMessage"></div>
                    </div>

                    {/* Company Name */}
                    <div className="element">
                        <label className="required">Company Name</label>
                        <input
                            type="text"
                            ref={refName}
                            name="name"
                            value={state.name ? state.name : ''}
                            onChange={handleChange}
                        />
                        <div ref={refNameError} className="errorMessage"></div>
                    </div>

                    {/* EPIC */}
                    <div className="element">
                        <label className="required">JIRA EPIC</label>
                        <input
                            type="text"
                            ref={refEpic}
                            name="epic"
                            value={state.epic ? state.epic : ''}
                            onChange={handleChange}
                        />
                        <div ref={refEpicError} className="errorMessage"></div>
                    </div>

                    {/* Platform */}
                    <div className="element">
                        <label className="required">Platform</label>
                        <select 
                            name="platform"
                            ref={refPlatform}
                            value={state.platform} 
                            onChange={handleChange}
                        >
                            <option>Select an option...</option>
                            <option>uStore</option>
                            <option>PageDNA</option>
                        </select>

                        <div ref={refPlatformError} className="errorMessage">
                            Please select a platform.
                        </div>
                    </div>

                    {/* Owner */}
                    <div className="element">
                        <label className="required">Owner</label>
                        {state.owner && state.owner.split(',').map((v, i) => (
                        <div 
                          key={i}
                          data-id={v}
                          className="portal-list-item"
                        >
                            {v}
                            <span 
                                className="delete-list-item"
                                title="Remove Owner"
                                onClick={deleteItem}
                            >x</span>
                        </div>
                        ))}
                            
                        <div className="owner-wrapper">
                            <input
                                type="text"
                                ref={refOwner}
                                name="add_owner"
                                defaultValue = ""
                                onChange={handleChange}
                                onKeyUp={keyUp}
                            />
                            <span 
                                className="add-owner"
                                title="Add Owner"
                                onClick={addItem}
                            >+</span>
                        </div>

                        <div ref={refOwnerError} className="errorMessage" />

                    </div>

                    {/* Status */}
                    <div className="element">
                        <label className="required">Status</label>
                        <select 
                            name="active"
                            ref={refStatus}
                            value={state.active} 
                            onChange={handleChange}
                        >
                            <option>Select an option...</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>

                        <div ref={refStatusError} className="errorMessage">
                            Please select a status.
                        </div>
                    </div>
                </div>
                
                <div ref={refSubmit} className="mt20 center">
                  <button className="primary-button" onClick={handleSubmit} title="Update Portal">Update</button>
                  <button className="reject-button" onClick={handleDelete} title="Delete Portal">Delete</button>
                </div>
            </div>
        </div>

        <div ref={refReturnTop} className="return-top" onClick={topFunction}>
            &#x25B2; Return to top
        </div>

        <div id="overlay" ref={overlay}></div>

        <div id="popup" ref={popup}>
            <h2 id="modal-heading" ref={modalHeading} className="center">Heading</h2>
            <span id="popup-close" ref={modalClose} className="close" onClick={closePopup}>&times;</span>
            <div id="modal-content" ref={modalContent} className="content center">
                Text changed through JS
            </div>
        </div>

        {/*  Popup for Updating of Portal Information Confirmation */}
        <div id="popup-confirm" ref={popupConfirm}>
            <h2 id="modal-heading" ref={popupConfirmHeading} style={{textAlign:'center'}}>Confirm Changes</h2>
            <span className="close" onClick={closePopup}>&times;</span>
            <div id="modal-content" ref={popupConfirmContent} className="content">
                Text changed through JS
            </div>
            <div className="mt10 center">
                <button className="primary-button smaller-button" onClick={(e) => {setConfirm(true)}} title="Yes">Yes</button>
                <button className="secondary-button smaller-button" onClick={() => {closePopup();setConfirm(false)}} title="No">No</button>
            </div>
        </div>
        
        {/* Popup for Delete Confirmation */}
        <div id="popup-delete-confirm" ref={popupDeleteConfirm}>
            <h2 id="modal-heading" style={{textAlign:'center'}}>
                Confirm Delete
            </h2>
            <span className="close" onClick={closePopup}>&times;</span>
            <div id="modal-content" className="content">
                <div className="center mt10">
                    Are you sure you want to delete this portal?
                </div>
            </div>
            <div className="mt10 center">
                <button className="primary-button smaller-button" onClick={(e) => {closePopup();setConfirmDelete(true)}} title="Yes">Yes</button>
                <button className="secondary-button smaller-button" onClick={() => {closePopup();setConfirmDelete(false)}} title="No">No</button>
            </div>
        </div>
        </>
        )
    }
}
