import { useState, useRef, useContext, useEffect } from 'react'
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { UserContext } from '../context/UserContext';
import { formatDate, formatDatePicker, formatSimpleDate, dbDate } from '../Utils/utils'

export default function Request() {
    let [ searchParams ] = useSearchParams();
    let rid = searchParams.get("id");
    const [state, setState] = useState(null)
    const [original, setOriginal] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [cancel, setCancel] = useState(false)
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    //const [totalNum, setTotalNum] = useState(null)
    const [slaveRequests, setSlaveRequests] = useState(null)
    //const [prevRequest, setPrevRequest] = useState(null)
    //const [nextRequest, setNextRequest] = useState(null)
    const [portals, setPortals] = useState(null);
    
    let companyName = null;
    let jira_id = null;
    let platform;

    // DOM References
    const refPortal = useRef();
    const refPortalError = useRef();
    const refName = useRef();
    const refNameError = useRef();
    const refPartNum = useRef();
    const refPartNumError = useRef();
    const refArrivalDate = useRef();
    const refDate = useRef();
    const refDateError = useRef();
    const refDateCharge = useRef();
    const refDisplayPrice = useRef();
    const refDisplayPriceError = useRef();
    const refCreditPrice = useRef();
    const refCreditPriceError = useRef();
    const refInternal = useRef();
    const refSubmit = useRef();
    const refReturnTop = useRef();
    const refVeraCore = useRef();
    const refVeraCoreError = useRef();
    const refOwner = useRef();
    const refOwnerError = useRef();
    const refRejection = useRef();
    const refRejectionWrapper = useRef();
    const refRejectionText = useRef();
    const refRejectionError = useRef();
    const refNextDay = useRef();
    const refApproveButton = useRef();
    const refRejectButton = useRef();
    const refCancelRejection = useRef();
    const refImage = useRef();
    const refImageDetails = useRef();
    const refImageError = useRef();
    const refErrors = useRef();
    const refNoImage = useRef();
    const refAttach = useRef();
    const refNewImages = useRef();
    const refLahlouh = useRef();
    const refEstimate = useRef();
    const refEstimateError = useRef();
    const refBatchFreq = useRef();
    const refHoldDate = useRef();
    const refExpectedQty = useRef();
    const refExpectedQtyError = useRef();
    const refArrivalDateError = useRef();
    const refCategory = useRef();
    const refCategoryError = useRef();
    const refUOM = useRef();
    const refUOMError = useRef();
    const refOrderQty = useRef();
    const refOrderQtyError = useRef();
    const refUserGroup = useRef();
    const refUserGroupError = useRef();
    const refRelatedRequests = useRef();
    const refExpandRelated = useRef();
    const refExpandArrow = useRef();
    const refBillPrice = useRef();
    const refBillPriceError = useRef();
    const refJiraNotes = useRef();
    const refJiraNote = useRef();

    // Overlay References
    const overlay = useRef();
    const loadingOverlay = useRef();
    const popup = useRef();
    const modalHeading = useRef();
    const modalContent = useRef();
    const modalClose = useRef();
    const popupConfirm = useRef();
    const popupConfirmHeading = useRef();
    const popupConfirmContent = useRef();
    const popupCancel = useRef();
    const popupCancelHeading = useRef();
    const popupCancelContent = useRef();
    
    if(refRejection.current){
      if(refRejection.current.style.display === 'block'){
        if(refApproveButton.current) refApproveButton.current.disabled = true;
        if(refRejectButton.current) refRejectButton.current.disabled = true;
      }
    }

    function nullToString(obj){
      for(let i=0;i<Object.values(obj).length;i++){
        if(Object.values(obj)[i] === null){
          obj[Object.keys(obj)[i]] = ''
        }
      }
      return obj;
    }

    function updateImageDetails(){
      //refImageDetails.current.innerHTML = 'Loading...';
      if(refImage.current.files[0]){
        let str = refImage.current.files.length;
          str += ' image';
          if(refImage.current.files.length > 1) str += 's';
          refImageDetails.current.innerHTML = str;

          setState(prevState => ({
            ...prevState,
            ['image']: 'newimages'
          }));
      }
  }

    // Pull in MySQL Data - Only once on page load
    useEffect(() => {
      // Verify JWT Token, and then pull data
      const verifyUser = async () => {
        const requestOptions = {
            method: 'POST',
            credentials: 'include',
        };

        let response = await fetch('https://picform.lahlouhonline.com/api/verify', requestOptions);
        let data = await response.json()
        
        if(data.username){
          // Set datepickers
          // let todaysDate = new Date();
          // todaysDate.setHours(0,0,0,0);
          
          // const newDate = new Date(addBusinessDays(todaysDate, 1)).toISOString().split('T')[0];
          // Datepicker - 1 business day in the future, gray out past days
          //refDate.current.setAttribute('min', newDate);
          //if(refArrivalDate.current) refArrivalDate.current.setAttribute('min', newDate);

          const requestOptions = {
            method: 'GET',
            credentials: 'include'
          };

          response = await fetch('https://picform.lahlouhonline.com/api/requests/' + rid, requestOptions)
          data = await response.json();
              
          if(data.name){
            await setState(nullToString(data))
            await setOriginal(nullToString(data))

            // Pull in other group requests if needed to get a total count
            if(data.group_request !== null && data.group_request !== '' && data.group_request !== 'master'){
              //let currentNum = parseInt(data.group_request.split('-')[1]);
              response = await fetch('https://picform.lahlouhonline.com/api/slaverequests/' + data.group_request.split('-')[0], requestOptions)
              data = await response.json();

              if(data && data.length > 0){
                let results = data.filter(item => item.id !== parseInt(rid))
                setSlaveRequests(results)
              }
            }
          }
        }else{
          await setUser(null)
        }
      }
      verifyUser();
    }, [])

    // State useEffect - Load portals once request is loaded
    useEffect(() => {
      const getPortals = async () => {
        if(state != null){
          const requestOptions = {
            method: 'GET',
            credentials: 'include'
          };

          let response = await fetch('https://picform.lahlouhonline.com/api/clients', requestOptions);
          let data = await response.json()

          // Pull in Portal List
          if(data){
            await setPortals(data);
            // Build Portal Select Dropdown
            let option;
            for(let a=0;a<data.length;a++){
                option = document.createElement("option");
                option.text = data[a].name;
                option.value = data[a].customer_number;
                refPortal.current.add(option);
            }

            // Set selected option
            refPortal.current.value = state.customer_number;

            // Set initial Jira Notes
            //displayJiraNotes();
          }
          
        }
      }

      if(user.role !== 'external') getPortals();
      
    }, [state])

    // Portals loaded - display jira notes
    useEffect(() => {
      if(state && state.request_status !== 'Completed'){
        displayJiraNotes();
      }
      
    }, [portals])


    // Handle the submission of the form when user is OK with the changes (custom confirm dialogue)
    useEffect(() => {
      //closePopup();
      if(confirm){
        handleSubmit();
      }
    }, [confirm])

    useEffect(() => {
      //closePopup();
      if(cancel){
        cancelRequest();
      }
    }, [cancel])

    function addBusinessDays(date, add){
      for (let i = 1; i <= add; i++) {
        date.setDate(date.getDate() + 1);
        if (date.getDay() === 6) {
            date.setDate(date.getDate() + 2);
        }
        else if (date.getDay() === 0) {
            date.setDate(date.getDate() + 1);
        }
      }

      return date;
    }

    // Parse Jira Paragraph
    async function parseParagraph(array){
      let html = '';
      //for(let b=0;b<subContentArray.length;b++){
      for(let a=0;a<array.length;a++){
          // Plain Text
          if(array[a].type === 'text' && !array[a].marks){
              html += array[a].text;
          }

          // Card Link
          if(array[a].type === 'inlineCard'){
              html += '<a href="' + array[a].attrs.url + '" target="_blank">'
              html += array[a].attrs.url;
              html += '</a>'
          }

          // Hard Line Breaks
          if(array[a].type === 'hardBreak'){
              html += '<br/>'
          }

          // Markup Text (hyperlink, strong, etc)
          if(array[a].type === 'text' && array[a].marks){
              // Place start tags
              for(let b=0;b<array[a].marks.length;b++){
                  // Hyperlink
                  if(array[a].marks[b].type === 'link'){
                      html += '<a href="' + array[a].marks[b].attrs.href + '" target="_blank">'
                  }

                  // Strong
                  if(array[a].marks[b].type === 'strong'){
                      html += '<strong>'
                  }

                  // Italic
                  if(array[a].marks[b].type === 'em'){
                      html += '<em>'
                  }

                  // Colored Text
                  if(array[a].marks[b].type === 'textColor'){
                      html += '<span style="color:' + array[a].marks[b].attrs.color + '">'
                  }
              }
              
              html += array[a].text

              // Place end tags
              for(let b=0;b<array[a].marks.length;b++){
                  // Hyperlink
                  if(array[a].marks[b].type === 'link'){
                      html += '</a>'
                  }

                  // Strong
                  if(array[a].marks[b].type === 'strong'){
                      html += '</strong>'
                  }

                  // Italic
                  if(array[a].marks[b].type === 'em'){
                      html += '</em>'
                  }

                  // Colored Text
                  if(array[a].marks[b].type === 'textColor'){
                      html += '</span>'
                  }
              }

          }
      }
      return html;
  }

  // Jira Notes
  const displayJiraNotes = async () => {
    if(portals && portals.length > 0){
      let client = await portals.filter(item => item.customer_number == refPortal.current.value)

      let requestOptions = {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' }
      };

      let response = await fetch('https://picform.lahlouhonline.com/api/jira/' + client[0].epic, requestOptions)
      let data = await response.json();
      if(!data.error){
          let newStr = '';
          if(data.fields.description){
            //Array of Jira content objects from the description field
            let contentArray = data.fields.description.content;
            let subContentArray = [];
            let tableRows, tableRow, tableCell;
            let listItems, listItem;

            for(let a=0;a<contentArray.length;a++){
                subContentArray.length = 0;
                // New Paragraph Content
                if(contentArray[a].type === 'paragraph'){
                    subContentArray = contentArray[a].content;
                    newStr += '<p>'
                    newStr += await parseParagraph(subContentArray)
                    newStr += '</p>'
                }

                // Heading Content
                if(contentArray[a].type === 'heading'){
                  subContentArray = contentArray[a].content;
                  newStr += '<h' + contentArray[a].attrs.level + '>';
                  newStr += await parseParagraph(subContentArray)
                  newStr += '</h' + contentArray[a].attrs.level + '>';
              }
                
                // Horizontal Rule
                if(contentArray[a].type === 'rule'){
                    newStr += '<div class="jira-divider"></div>';
                }

                // Bullet List Items
                if(contentArray[a].type === 'bulletList'){
                    listItems = contentArray[a].content;
                    newStr += '<ul>'
                    for(let b=0;b<listItems.length;b++){
                      if(listItems[b].type === 'listItem'){
                        // Bullet List Item
                        listItem = listItems[b].content;
                        for(let c=0;c<listItem.length;c++){
                            if(listItem[c].type === 'paragraph'){
                                newStr += '<li>'
                                newStr += await parseParagraph(listItem[c].content)
                                newStr += '</li>'
                            }
                        }
                      }
                    }
                    newStr += '</ul>'
                }

                // Ordered List Items
                if(contentArray[a].type === 'orderedList'){
                  listItems = contentArray[a].content;
                  newStr += '<ol>'
                  for(let b=0;b<listItems.length;b++){
                    if(listItems[b].type === 'listItem'){
                      // Ordered List Item
                      listItem = listItems[b].content;
                      for(let c=0;c<listItem.length;c++){
                          if(listItem[c].type === 'paragraph'){
                              newStr += '<li>'
                              newStr += await parseParagraph(listItem[c].content)
                              newStr += '</li>'
                          }
                      }
                    }
                  }
                  newStr += '</ol>'
                }

                // Table
                if(contentArray[a].type === 'table'){
                    newStr += '<table class="jira-table" cellpadding="0" cellspacing="0">'
                    tableRows = contentArray[a].content;
                    for(let b=0;b<tableRows.length;b++){
                        if(tableRows[b].type === 'tableRow'){
                            tableRow = tableRows[b].content;
                            
                            for(let c=0;c<tableRow.length;c++){
                                if(tableRow[c].type === 'tableCell'){
                                    tableCell = tableRow[c].content;
                                    if(tableRow[c].attrs.rowspan){
                                        newStr += '<td rowspan="' + tableRow[c].attrs.rowspan + '">'
                                    }else{
                                        newStr += '<td>'
                                    }
                                    for(let d=0;d<tableCell.length;d++){
                                        // Paragraph Content
                                        if(tableCell[d].type === 'paragraph'){
                                          newStr += '<p>'  
                                          newStr += await parseParagraph(tableCell[d].content)
                                          newStr += '<p>'
                                        }

                                        // Heading Content
                                        if(tableCell[d].type === 'heading'){
                                          newStr += '<h' + contentArray[a].attrs.level + '>';
                                          newStr += await parseParagraph(tableCell[d].content)
                                          newStr += '</h' + contentArray[a].attrs.level + '>';
                                      }

                                        // Bullet List Items
                                        if(tableCell[d].type === 'bulletList'){
                                            listItems = tableCell[d].content;
                                            newStr += '<ul>'
                                            for(let e=0;e<listItems.length;e++){
                                                if(listItems[e].type === 'listItem'){
                                                    // Bullet List Item
                                                    listItem = listItems[e].content;
                                                    for(let f=0;f<listItem.length;f++){
                                                        if(listItem[f].type === 'paragraph'){
                                                            newStr += '<li>'
                                                            newStr += await parseParagraph(listItem[f].content)
                                                            newStr += '</li>'
                                                        }
                                                    }
                                                }
                                            }
                                            newStr += '</ul>'
                                        }

                                        // Ordered List Items
                                        if(tableCell[d].type === 'orderedList'){
                                          listItems = tableCell[d].content;
                                          newStr += '<ol>'
                                          for(let e=0;e<listItems.length;e++){
                                            if(listItems[e].type === 'listItem'){
                                              // Ordered List Item
                                              listItem = listItems[e].content;
                                              for(let f=0;f<listItem.length;f++){
                                                  if(listItem[f].type === 'paragraph'){
                                                      newStr += '<li>'
                                                      newStr += await parseParagraph(listItem[f].content)
                                                      newStr += '</li>'
                                                  }
                                              }
                                            }
                                          }
                                          newStr += '</ol>'
                                        }
                                    }
                                    newStr += '</td>'
                                }
                            }
                        }
                        newStr += '</tr>';
                    }
                    newStr += '</table>'
                }       
            }
          }

          if(newStr.length > 20){
            refJiraNote.current.innerHTML = newStr;
            //refJiraNotes.current.style.display = 'block';
          }else{
            refJiraNote.current.innerHTML = 'No description entered for this Jira ticket'
            //refJiraNotes.current.style.display = 'block';
          }
          refJiraNotes.current.style.display = 'block';
      }else{
          console.log('Issue accessing Jira Description')
      }
    }
  }
  // End of Jira Notes

    // Handler for all inputs
    const handleChange = async e => {
        const { name, value } = e.target;

        if(name === 'customer_number'){
          refJiraNote.current.innerHTML = '';
          refJiraNotes.current.style.display = 'none';
          //displayJiraNotes()
        }

        if(name === "effective_date"){
          refDateCharge.current.classList.remove("show");
          // Next Day Logic
          let nextDay = false;
          const todaysDate = new Date();
          todaysDate.setHours(0,0,0,0);
  
          // Grab entered effective date at midnight
          const eDate = new Date(value + ' 00:00:00');
  
          // Add 1 business day to today's date
          const newDate = new Date(addBusinessDays(todaysDate, 1));
          
          // Date is 1 business day in the future
          if(eDate.getTime() === newDate.getTime()){
            refDateCharge.current.classList.add("show");
          } 
        }
        
        if(name === 'hold-date-check'){
          const setValue = state.hold_date === 'yes' ? 'no' : 'yes';
          setState(prevState => ({
            ...prevState,
            ['hold_date']: setValue
          }));  
        }else if(name === 'next-day-check'){
          const setValue = state.next_day === 'yes' ? 'no' : 'yes';
          setState(prevState => ({
            ...prevState,
            ['next_day']: setValue
          }));  
        }else{
          setState(prevState => ({
            ...prevState,
            [name]: value
          }));
        }
    };

    /////////////////////////////
    // Jira Ticket Creation
    /////////////////////////////
    async function createJira(approver_email, imageFilename=null){
      // JIRA 'Change Reason'
      let changeObj = {};
      if(state.action.indexOf('Change') != -1 || state.action.indexOf('Update') != -1){
        changeObj = {
          "self": "https://lahlouh.atlassian.net/rest/api/2/customFieldOption/10118",
          "value": "Update Product",
          "id": "10118"
        }
      }

      if(state.action.indexOf('Remove') != -1){
        changeObj = {
          "self": "https://lahlouh.atlassian.net/rest/api/2/customFieldOption/10117",
          "value": "Remove Product",
          "id": "10117"
        }
      }

      if(state.action.indexOf('Add') != -1){
        changeObj = {
          "self": "https://lahlouh.atlassian.net/rest/api/2/customFieldOption/10108",
          "value": "Add Product",
          "id": "10108"
        }
      }

      // Jira 'Due Date' (Today plus 2 business days) - ISO Format
      //let today = new Date();
      // today.setHours(0,0,0,0);
      // let jiraDate = new Date(addBusinessDays(today, 2)).toISOString().split('T')[0];
      
      // Jira Due Date - Based on Effective Date
      let jiraDate = new Date(state.effective_date).toISOString().split('T')[0]

      // Jira Effective Date
      let split = state.effective_date.split('-')
      let jiraEffDate = split[1] + '/' + split[2] + '/' + split[0];
      
      // Add 1 business day when 'Next day charge' is checked
      // Not being used currently
      // if(refNextDay.current){
      //   if(refNextDay.current.checked){
      //     jiraDate = new Date(addBusinessDays(today, 1)).toISOString().split('T')[0];
      //   }
      // }

      // Match Customer Number to JIRA EPIC through the Clients table
      let requestOptions = {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' }
      };

      let epic = null;
      let response = await fetch('https://picform.lahlouhonline.com/api/client/' + state.customer_number, requestOptions)
      let data = await response.json();

      companyName = data.name;
      let platform = data.platform;
      if(data.epic){
        epic = data.epic;
      }

      let jiraDesc = "*PIC Request:*\nhttps://picform.lahlouhonline.com/request?id=" + state.id;
    
      // Submitted By
      jiraDesc += "\n\n*Submitted By:* " + state.submitter_email;

      // Approved By
      //jiraDesc += "\n\n*Approved By:* " + requestObj.approver_email;
      jiraDesc += "\n\n*Approved By:* " + approver_email;

      // Platform
      if(platform !== '' && platform !== null && platform.length > 0){
        jiraDesc += "\n\n*Platform:* " + platform;
      }

      // Portal
      jiraDesc += "\n\n*Portal:* " + companyName;

      // Task
      jiraDesc += "\n\n*Task:* " + state.action + " - " + state.vc_product_id + " - " + state.name;
      
      // Title
      jiraDesc += "\n\n*Title:* " + state.name;

      // Short Description
      jiraDesc += "\n\n*Short Description:* \n" + state.short_desc;

      // Long Description
      jiraDesc += "\n\n*Long Description:* \n" + state.description;

      // Client SKU
      jiraDesc += "\n\n*Client SKU:* " + state.partnum;
      
      // VeraCore Product ID
      jiraDesc += "\n\n*VeraCore SKU:* " + state.vc_product_id;

      // Display Client Part No. (SKU)?
      if(state.append_partnum !== '' && state.append_partnum !== null && state.append_partnum.length > 0){
        jiraDesc += "\n\n*Display Client Part Number?* " + state.append_partnum;
      }

      // Owner
      jiraDesc += "\n\n*Owner:* " + state.owner;

      // Job Number
      if(state.job_number !== '' && state.job_number !== null && state.job_number.length > 0){
        jiraDesc += "\n\n*Job Number:* " + state.job_number;
      }

      // Template Number
      if(state.estimate_number !== '' && state.estimate_number !== null && state.estimate_number.length > 0){
        jiraDesc += "\n\n*Estimate Number:* " + state.estimate_number;
      }
      
      // Lahlouh Supplied Image
      if(refLahlouh.current && refLahlouh.current.checked){
        jiraDesc += "\n\n*Lahlouh Supplied Image?* yes";
      }

      // Display Price
      if(state.display_price !== '' && state.display_price !== null && state.display_price.length > 0){
        jiraDesc += "\n\n*Display Price:* " + state.display_price;
      }
      // Credit Price
      if(state.credit_price !== '' && state.credit_price !== null && state.credit_price.length > 0){
        jiraDesc += "\n\n*Credit/Reimbursement Price:* " + state.credit_price;
      }
        // UOM
      if(state.uom !== '' && state.uom !== null && state.uom.length > 0){
        jiraDesc += "\n\n*UOM:* " + state.uom;
      }
      // Display UOM?
      if(state.display_uom !== '' && state.display_uom !== null && state.display_uom.length > 0){
        jiraDesc += "\n\n*Display UOM?* " + state.display_uom;
      }
      // Orderable Qty
      if(state.orderable_qty !== '' && state.orderable_qty !== null && state.orderable_qty.length > 0){
        jiraDesc += "\n\n*Orderable Qty:* " + state.orderable_qty;
      }
      // Re-Order Point
      if(state.re_order_point !== '' && state.re_order_point !== null && state.re_order_point.length > 0){
        jiraDesc += "\n\n*Re-Order Point:* " + state.re_order_point;
      }
      // Web Category
      if(state.categpory !== '' && state.category !== null && state.category.length > 0){
        jiraDesc += "\n\n*Web Category:* " + state.category;
      }
      // User Group(s)
      if(state.user_groups !== '' && state.user_groups !== null && state.user_groups.length > 0){
        jiraDesc += "\n\n*User Group(s):* " + state.user_groups;
      }
      // Order Approvals Required?
      if(state.approval_required !== '' && state.approval_required !== null){
        jiraDesc += "\n\n*Approval Required?* " + state.approval_required;
      }
      // International Shipping?
      if(state.international !== '' && state.international !== null){
        jiraDesc += "\n\n*International Shipping?* " + state.international;
      }
      // Effective Date
      if(state.effective_date !== '' && state.effective_date !== null){
        jiraDesc += "\n\n*Effective Date:* " + jiraEffDate;
      }
      // Comments
      if(state.client_notes !== '' && state.client_notes !== null && state.client_notes.length > 0){
        jiraDesc += "\n\n*Comments:*\n" + state.client_notes;
      }
      
      // Image Links
      if(!imageFilename){
        //if(refLahlouh.current && !refLahlouh.current.checked){
          //if(state.image !== '' && state.image !== null && state.image.length > 0)
        if(refAttach.current && refAttach.current.checked){
          if(original.image !== '' && original.image !== null && original.image.length > 0){
            let split = original.image.split(',')
            for(let a=0;a<split.length;a++){
              jiraDesc += "\n\n*Image Link:* https://picform.lahlouhonline.com/product-images/" + split[a];
            }
          }
        }
      }else{
        let split = imageFilename.split(',')
          for(let a=0;a<split.length;a++){
            jiraDesc += "\n\n*Image Link:* https://picform.lahlouhonline.com/product-images/" + split[a];
          }
      }
      
      // Create JIRA Ticket (Admin Approval)
      let liveOn = 'Live ASAP';
      if(state.hold_date === 'yes'){
        liveOn = 'Live on ' + jiraEffDate;
      }

      let jiraObj = {
        "fields": 
        { 
            "project": 
            { 
                "key": "IT" 
            },
            "summary": "OPICF - " + liveOn + " - " + companyName + " - " + state.action + " - " + state.vc_product_id + " - " + state.name, 
            "description": jiraDesc,
            "issuetype": 
            { 
                "name": "Task" 
            }, 
            "customfield_10055": {
              "self": "https://lahlouh.atlassian.net/rest/api/2/customFieldOption/10112",
              "value": "Online",
              "id": "10112"
            },
            "customfield_10007": changeObj,
            "duedate": jiraDate
        },
        "id": state.id
      } 

      if(epic){
        jiraObj.fields.customfield_10014 = epic;
      }

      requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(jiraObj)
      };

      response = await fetch('https://picform.lahlouhonline.com/api/jira/', requestOptions)
      data = await response.json();

      // Set JIRA ticket ID
      jira_id = data.key

      // Update JIRA Request Status from Backlog to Blocked
      jiraObj = {
        "transition": {
            "id": "321"
        }
      }

      requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(jiraObj)
      };

      response = await fetch('https://picform.lahlouhonline.com/api/jira-update/' + data.key, requestOptions)
    }
    // End of Jira Ticket Creation

    async function handleSubmit(e){
      let shouldSubmit = true;
      let nextDay = false;
      let requestOptions = {};

      refName.current.classList.remove('error-outline');
      refNameError.current.style.display='none';
      refPartNum.current.classList.remove('error-outline');
      refPartNumError.current.style.display='none';
      refDate.current.classList.remove('error-outline');
      refDateError.current.style.display='none';

      if(refCategory.current) refCategory.current.classList.remove('error-outline');
      if(refCategoryError.current) refCategoryError.current.style.display='none';
      if(refArrivalDate.current) refArrivalDate.current.classList.remove('error-outline');
      if(refArrivalDateError.current) refArrivalDateError.current.style.display='none';
      if(refExpectedQty.current) refExpectedQty.current.classList.remove('error-outline');
      if(refExpectedQtyError.current) refExpectedQtyError.current.style.display='none';
      if(refUOM.current) refUOM.current.classList.remove('error-outline');
      if(refUOMError.current) refUOMError.current.style.display='none';
      if(refOrderQty.current) refOrderQty.current.classList.remove('error-outline');
      if(refOrderQtyError.current) refOrderQtyError.current.style.display='none';
      if(refUserGroup.current) refUserGroup.current.classList.remove('error-outline');
      if(refUserGroupError.current) refUserGroupError.current.style.display='none';

      if(refImageError.current) refImageError.current.style.display='none';
      if(refVeraCore.current) refVeraCore.current.classList.remove('error-outline');
      if(refVeraCoreError.current) refVeraCoreError.current.style.display='none';
      if(refOwner.current) refOwner.current.classList.remove('error-outline');
      if(refOwnerError.current) refOwnerError.current.style.display='none';
      if(refEstimate.current) refEstimate.current.classList.remove('error-outline');
      if(refEstimateError.current) refEstimateError.current.style.display='none';
      refRejectionWrapper.current.classList.remove('error-outline');
      refRejectionText.current.style.backgroundColor = "#fff";
      refRejectionError.current.style.display='none';
      if(refDisplayPrice.current){
        refDisplayPrice.current.classList.remove('error-outline');
        refDisplayPriceError.current.style.display='none';
      }
      if(refPortal.current){
        refPortal.current.classList.remove('error-outline');
        refPortalError.current.style.display='none';
      }
      if(refCreditPrice.current){
        refCreditPrice.current.classList.remove('error-outline');
        refCreditPriceError.current.style.display='none';
      }
      refErrors.current.classList.remove('show');

      if(state.partnum === ''){
        refPartNumError.current.innerHTML = 'Please enter a part number.';
        refPartNum.current.classList.add('error-outline');
        refPartNumError.current.style.display='block';
        shouldSubmit = false;
      }

      // Image Validation (Fix and Received status)
      if(refNewImages.current && refNewImages.current.checked){
        if(refImage.current && refImage.current.files[0]){
          let type;
          for(let a=0;a<refImage.current.files.length;a++){
              type = refImage.current.files[a].type;
              if(type.indexOf('jpeg') === -1 && type.indexOf('png') === -1){
                  refImageError.current.innerHTML = 'Please upload images in JPG or PNG format.'
                  refImageError.current.style.display='block';
                  shouldSubmit = false;
                  break;
              }
          }
          if(refImage.current.files.length > 5){
              refImageError.current.innerHTML = 'Please upload a maximum of 5 files.'
              refImageError.current.style.display='block';
              shouldSubmit = false;
          }
        }else{
          refImageError.current.innerHTML = 'Please attach at least one image.'
          refImageError.current.style.display='block';
          shouldSubmit = false;
        }
      }

      // Name Validation
      if(state.name === ''){
          refName.current.classList.add('error-outline');
          refNameError.current.style.display='block';
          shouldSubmit = false;
      }

      // UOM Validation
      if(state.action.indexOf('Add') !== -1){
        if(state.uom === ''){
            refUOM.current.classList.add('error-outline');
            refUOMError.current.style.display='block';
            shouldSubmit = false;
        }
      }

      // Orderable Qty Validation
      if(state.action.indexOf('Add') !== -1){
        if(state.orderable_qty === ''){
            refOrderQty.current.classList.add('error-outline');
            refOrderQtyError.current.style.display='block';
            shouldSubmit = false;
        }
      }

      // User Group Validation
      if(state.action.indexOf('Add') !== -1 && user.role !== "external"){
        if(state.user_groups === ''){
            refUserGroup.current.classList.add('error-outline');
            refUserGroupError.current.style.display='block';
            shouldSubmit = false;
        }
      }

      // Category Validation
      if(state.action.indexOf('Add') !== -1){
        if(state.category === ''){
            refCategory.current.classList.add('error-outline');
            refCategoryError.current.style.display='block';
            shouldSubmit = false;
        }
      }

      if((state.effective_date === '0000-00-00' || state.effective_date === '') && user.role !== "external"){
          refDateError.current.innerHTML = 'Please enter an effective date.';
          refDate.current.classList.add('error-outline');
          refDateError.current.style.display='block';
          shouldSubmit = false;
      }

      // Expected Arrival Date Validation
      if(state.action.indexOf('Add') !== -1 && user.role !== 'external'){
        if(state.arrival_date === ''){
            refArrivalDate.current.classList.add('error-outline');
            refArrivalDateError.current.style.display='block';
            shouldSubmit = false;
        }
      }

      // Expected Qty Validation
      if(state.action.indexOf('Add') !== -1 && user.role !== "external"){
          if(state.expected_qty === ''){
              refExpectedQty.current.classList.add('error-outline');
              refExpectedQtyError.current.style.display='block';
              shouldSubmit = false;
          }
      }

      // Effective Date Validation
      // Especially for working on pending tickets
      if(state.effective_date !== '' && state.effective_date != '0000-00-00' && user.role !== "external"){
        // Grab todays date and set hours to exactly midnight
        const todaysDate = new Date();
        todaysDate.setHours(0,0,0,0);

        // Grab entered effective date at midnight
        const eDate = new Date(state.effective_date + ' 00:00:00');

        // Add 1 business day to today's date
        const newDate = new Date(addBusinessDays(todaysDate, 1));
        
        // Date is today or earlier
        if(eDate.getTime() < newDate.getTime()){
            refDateError.current.innerHTML = 'Please choose a date at least 1 business day in the future.';
            refNextDay.current.checked = false;
            refDateCharge.current.classList.remove("show");
            refDate.current.classList.add('error-outline');
            refDateError.current.style.display='block';
            shouldSubmit = false;
        }

        // Date is only 1 business day in the future
        // Add next day messaging
        if(eDate.getTime() === newDate.getTime()){
          nextDay = true;  
          if(!refNextDay.current.checked){
                refDate.current.classList.add('error-outline');
                refDateCharge.current.classList.add("show");
                shouldSubmit = false;
            }
        }
      }

      if(refVeraCore.current){
        if(state.vc_product_id === ''){
          refVeraCoreError.current.innerHTML = 'Please enter the VeraCore Product ID.';
          refVeraCore.current.classList.add('error-outline');
          refVeraCoreError.current.style.display='block';
          shouldSubmit = false;
        }

        // VeraCore Product ID RegEx
        if(state.vc_product_id !== ''){
            const regex = /^[0-9A-Za-z-]+$/
            if(!state.vc_product_id.match(regex)){
                refVeraCoreError.current.innerHTML = 'Only numbers, letters and dashes are allowed in the part number.';
                refVeraCore.current.classList.add('error-outline');
                refVeraCoreError.current.style.display='block';
                shouldSubmit = false;
            }
        }
      }

      if(refOwner.current){
        if(state.owner === ''){
          refOwner.current.classList.add('error-outline');
          refOwnerError.current.style.display='block';
          shouldSubmit = false;
        }
      }
      
      if(refRejection.current.style.display === 'block' && (state.fix_notes === '' || state.fix_notes === null)){
        refRejectionText.current.style.backgroundColor = "#ffecec";
        refRejectionWrapper.current.classList.add('error-outline');
        refRejectionError.current.style.display='block';
        shouldSubmit = false;
      }

      // Display Price Validation
      if(refDisplayPrice.current){
        if(state.display_price != ''){
          if(isNaN(state.display_price)){
              refDisplayPrice.current.classList.add('error-outline');
              refDisplayPriceError.current.innerHTML = 'Display price must be numeric.';
              refDisplayPriceError.current.style.display='block';
              shouldSubmit = false;
          }
        }
      }

      // Credit Price Validation
      if(refCreditPrice.current){
        if(state.credit_price != ''){
          if(isNaN(state.credit_price)){
              refCreditPrice.current.classList.add('error-outline');
              refCreditPriceError.current.innerHTML = 'Credit price must be numeric.';
              refCreditPriceError.current.style.display='block';
              shouldSubmit = false;
          }
        }
      }

      if(user.role === 'admin' || user.role === 'sales'){
        // POD Validation (one thing turns on another)
        if(refBatchFreq.current){
          if(refBatchFreq.current.options[0].selected || refBatchFreq.current.options[1].selected){
          }else{
              if(refEstimate.current.value === ''){
                  refEstimate.current.classList.add('error-outline');
                  refEstimateError.current.style.display='block';
                  shouldSubmit = false;
              }
          }
        }

        if(refPortal.current.value.indexOf('Select an') != -1){
            refPortal.current.classList.add('error-outline');
            refPortalError.current.style.display='block';
            shouldSubmit = false;
        }else{
            // Grab Company Name from Clients Table
            let requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' }
            };

            let response = await fetch('https://picform.lahlouhonline.com/api/client/' + state.customer_number, requestOptions)
            let data = await response.json();
            if(!data.error){
                companyName = data.name;
            }
        }
      // Add Company Name for Teams Message when role = External
      }else{
        requestOptions = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        let response = await fetch('https://picform.lahlouhonline.com/api/client/' + user.customer_number, requestOptions)
        let data = await response.json();
        if(!data.error){
            companyName = data.name;
            platform = data.platform;
        }else{
            companyName = 'Unknown';
            platform = 'Unknown';
        }
      }
      
      if(!shouldSubmit){
        if(refErrors.current){
          if(refRejectionError.current.style.display !=='block'){
            refErrors.current.classList.add('show');
          }
        }
      }

      // Check for any changes to form fields
      if(shouldSubmit){
       
        if(JSON.stringify(original) !== JSON.stringify(state)){
          if(!confirm) confirmOverlay();
        // Data is the same
        }else{
          // Fix request - Make sure request was fixed
          //if(state.request_status === 'Fix' && user.role !== 'sales'){
          if(state.request_status === 'Fix'){
            infoModal('Changes are needed', 'Please address any items listed in the fix notes before submitting')
          // Sales or External - No changes detected  
          }else if(user.role !== 'admin'){
            infoModal('No changes detected', 'No form updates were detected. Please make any changes as needed')
          // Submitter updating a pending ticket, but no changes detected
          }else if(state.request_status === 'Pending' && user.username === state.submitter_email){
            infoModal('No changes detected', 'No form updates were detected. Please make any changes as needed')
          // Admin Approval (No Changes)
          }else{
            showLoading();
            const requestObj = {}
            requestObj.request_status = 'In Progress'

            // Date approved / Approver email when admin submittal
            if(user.role === 'admin' && state.request_status !== 'Fix' && state.request_status !== 'Pending'){
              let todaysDate = new Date()
              requestObj.date_approved = dbDate(todaysDate);
              requestObj.approver_email = user.username;
            }
            
            await createJira(requestObj.approver_email);
            requestObj.jira_id = jira_id;

            // Submit to PICDB (Without Changes) - Admin Approval Only
            requestOptions = {
              method: 'PATCH',
              credentials: 'include',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(requestObj)
            };
          
            let response = await fetch('https://picform.lahlouhonline.com/api/requests/' + state.id, requestOptions)

            if(response.status === 403){
              hideLoading();
              infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
            }else{
              let data = await response.json()
              hideLoading();

              if(data.name){
                // Group Request - Refresh Page
                if(data.group_request !== null && data.group_request !== ''){
                  setState(prevState => ({
                    ...prevState,
                    request_status: 'In Progress',
                    date_approved: requestObj.date_approved,
                    jira_id: requestObj.jira_id
                  }));
                  infoModal("Approved", "This request has been approved", true)
                // Single Request - Redirect to requests page
                }else{
                  infoModal("Approved", "This request has been approved", false, true)

                  setTimeout(() => {
                    navigate('/requests');  
                  }, 2750);
                }
              }else{
                infoModal("Server Error", "There was an issue communicating with the server. Please try again later", true)
              }
            }
          }
        }
        
        // Data is different (Admin Approval / Original Submitter Update)
        // Confirmed and ready to submit
        if(confirm){
          showLoading();
          
          let newRequest = state.request_status === 'In Review' ? true : false;
          let justFixed = false;

          let effectiveDate = new Date(state.effective_date);
          effectiveDate = effectiveDate.toISOString().split('T')[0];
          
          let requestObj = {
            action: state.action,
            partnum: state.partnum,
            name: state.name.trim(),
            effective_date: effectiveDate
          }
          
          // Request Status Updates
          if(user.role === 'external' && state.request_status === 'In Review'){
            requestObj.request_status = 'In Review';  
          }else{
            requestObj.request_status = 'Received';
          }

          if(state.request_status === 'Received'){
            if(state.fix_notes !== '' && state.fix_notes !== null){
              requestObj.request_status = 'Fix';
            }else{
              // Sales/ External Update
              if(user.role !== 'admin'){
                requestObj.request_status = 'Received';  
              // Admin Approval
              }else{
                requestObj.request_status = 'In Progress';
              }
            }
          } 
          
          if(state.request_status === 'Fix'){
            justFixed = true;
            requestObj.request_status = 'Received';
          }

          if(state.request_status === 'Pending'){
            requestObj.request_status = 'Pending';
          }

          if(state.inventory) requestObj.inventory = state.inventory
          if(state.short_desc) requestObj.short_desc = state.short_desc
          if(state.description) requestObj.description = state.description
          if(state.uom) requestObj.uom = state.uom
          if(state.orderable_qty) requestObj.orderable_qty = state.orderable_qty
          if(state.re_order_point) requestObj.re_order_point = state.re_order_point
          if(state.category) requestObj.category = state.category
          if(state.user_groups) requestObj.user_groups = state.user_groups
          if(state.arrival_date) requestObj.arrival_date = state.arrival_date
          if(state.expected_qty) requestObj.expected_qty = state.expected_qty
          if(state.display_price) requestObj.display_price = state.display_price
          if(state.credit_price) requestObj.credit_price = state.credit_price
          if(state.batch_freq) requestObj.batch_freq = state.batch_freq
          if(state.item_type) requestObj.item_type = state.item_type
          if(state.approval_required) requestObj.approval_required = state.approval_required
          if(state.international) requestObj.international = state.international
          if(refNextDay.current){
            if(refNextDay.current.checked && nextDay){
              requestObj.next_day = "yes";
            }else{
              requestObj.next_day = "";
            }
          }
          if(state.client_notes) requestObj.client_notes = state.client_notes
          if(state.vc_product_id) requestObj.vc_product_id = state.vc_product_id
          if(state.owner) requestObj.owner = state.owner
          if(state.job_number) requestObj.job_number = state.job_number
          if(state.estimate_number) requestObj.estimate_number = state.estimate_number
          
          requestObj.customer_number = state.customer_number;
          
          //requestObj.fix_notes = state.request_status === 'Fix' ? '' : state.fix_notes
          requestObj.fix_notes = state.fix_notes;
          
          // Date approved / Approver email on Admin Approval
          if(user.role === 'admin' && requestObj.request_status !== 'Fix' && state.request_status !== 'Fix' && requestObj.request_status !== 'Pending' && state.request_status !== 'Pending'){
            let todaysDate = new Date()
            requestObj.date_approved = dbDate(todaysDate);
            requestObj.approver_email = user.username;
          }

          // Original Images Still Attached
          if(state.image !== 'newimages' && state.image !== 'lahlouh' && state.image !== 'none'){
            requestObj.image = original.image;
          }
        
          // Lahlouh Supplied Image
          if(refLahlouh.current){
            if(refLahlouh.current.checked){
              requestObj.image = 'lahlouh';
            }
          }

          // No Image Needed
          if(refNoImage.current){
            if(refNoImage.current.checked){
              requestObj.image = 'none';
            }
          }
         
          // Submit Images If Attached
          let imageFilename = null
          if(refNewImages.current && refNewImages.current.checked){
            if(refImage.current && refImage.current.files[0]){
              let formData = new FormData()
              let requestOptions;

              for(let a=0;a<refImage.current.files.length;a++){
                  formData = new FormData();
                  formData.append('image', refImage.current.files[a])
                  requestOptions = {
                      method: 'POST',
                      credentials: 'include',
                      redirect: 'manual',
                      body: formData,
                  };
                  
                  let response = await fetch('https://picform.lahlouhonline.com/api/image', requestOptions)
                  
                  if(response.status === 403){
                      hideLoading();
                      infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
                      return false;
                  }else{
                      let data = await response.json()
                      if(data.filename){
                          if(a===0){
                              imageFilename = data.filename;
                          }else{
                              imageFilename += ',' + data.filename;
                          }
                      }
                  }
              }
              requestObj.image = imageFilename;
            }
          }

          // Create JIRA (With Changes) Admin Approval Only - Not Original Submitter Update
          if(requestObj.request_status === 'In Progress'){
            await createJira(requestObj.approver_email, imageFilename)
            requestObj.jira_id = jira_id;
          }
          
          // Submit to PICDB (With Changes)
          // Admin Approval / Original Submitter Update / Sales Approval (from external submittal)
          requestOptions = {
            method: 'PATCH',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestObj)
          };
        
          let response = await fetch('https://picform.lahlouhonline.com/api/requests/' + state.id, requestOptions)
          
          if(response.status === 403){
            hideLoading();
            infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
          }else{
            let data = await response.json()
              // Submit to Teams
              // Request is back to 'Received' / Sales approval of external request
              
              // New or Updated Request
              let str;
              if(newRequest) str = "New request";
              if(!newRequest) str = "Update to existing request";
              if(justFixed) str = "Fixed request"

              if(requestObj.request_status === 'Received'){
                // Teams Message
                const teamsObj =
                {
                    "@context": "https://schema.org/extensions",
                    "@type": "MessageCard",
                    "themeColor": "0076D7",
                    "summary": "Summary",
                    "sections": [
                        {
                          "activityTitle": "Portal Item Change Request",
                            "facts": [
                              {
                                "name": "Type:",
                                "value": str
                              },
                              {
                                  "name": "Action:",
                                  "value": state.action
                              },
                              {
                                "name": "Company:",
                                "value": companyName
                              },
                              {
                                "name": "Updated By:",
                                "value": user.username
                              },
                              {
                                "name": "Role:",
                                "value": user.role
                              },
                              {
                                "name": "Effective Date:",
                                "value": formatDatePicker(new Date(state.effective_date))
                              }
                            ],
                            "text": "https://picform.lahlouhonline.com/request?id=" + data.id
                        }
                    ]
                }

                // Send to Teams
                requestOptions = {
                    method: 'POST',
                    //credentials: 'include',
                    mode: 'no-cors',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(teamsObj)
                };

            
                // Don't send Teams for external update
                if(user.role !== 'external'){
                  //fetch('https://lahlouhinc.webhook.office.com/webhookb2/a51eee01-4ded-4410-ae26-8ecd3f547d46@55d9408b-9060-4745-9c50-44dba74d1b39/IncomingWebhook/c7cb6c6f43bc44a69ce761f64df16b3b/b11fe407-83f0-4667-96ae-7740b0ea7b21', requestOptions)
                  await fetch('https://picform.lahlouhonline.com/api/teams/', requestOptions)
                }
              }
              
              hideLoading();
              
              if(data.name){
                // Group Request
                if(data.group_request !== null && data.group_request !== ''){
                  if(requestObj.request_status === 'Fix'){
                    setState(prevState => ({
                      ...prevState,
                      request_status: 'Received',
                    }));
                  }

                  // Admin approval
                  if(user.role === 'admin'){
                    if(requestObj.request_status === 'Fix' || requestObj.request_status === 'Pending'){
                      infoModal("Updated", "This request has been updated", true)

                    }else{
                      infoModal("Approved", "This request has been approved", true)
                      setState(prevState => ({
                        ...prevState,
                        request_status: 'In Progress',
                        date_approved: requestObj.date_approved,
                        jira_id: requestObj.jira_id
                      }));
                    }
                  // Sales / External update
                  }else{
                    infoModal("Updated", "This request has been updated", true)
                  }
                  
                  // Redirect when admin submitting fix on group request item
                  if(requestObj.request_status === 'Fix'){
                    setTimeout(() => {
                      navigate('/requests');  
                    }, 2750);
                  }
                  
                // Single Request - Redirect to requests page
                }else{
                  // Admin approval
                  if(user.role === 'admin'){
                    if(requestObj.request_status === 'Fix' || requestObj.request_status === 'Pending'){
                      infoModal("Updated", "This request has been updated", false, true)
                    }else{
                      infoModal("Approved", "This request has been approved", false, true)
                    }
                  // Sales update (External is locked down)
                  }else{
                    infoModal("Updated", "This request has been updated", false, true)
                  }

                  setTimeout(() => {
                    navigate('/requests');  
                  }, 2750);
                }
              }else{
                infoModal("Server Error", "There was an issue communicating with the server. Please try again later", true)
              }
          }
          setTimeout(() => {
            setOriginal(state) 
            setConfirm(false)
          }, 500)
        }
      }
    }
    // End of handleSubmit

    function showRejection(){
      refApproveButton.current.disabled = true;
      refRejectButton.current.disabled = true;
      refRejection.current.style.display = 'block';
      refSubmit.current.style.display = 'block';
      refCancelRejection.current.style.display = 'inline-block';
    }

    function cancelReject(){
      refRejection.current.style.display = 'none';
      setState(prevState => ({
        ...prevState,
        fix_notes: ''
      }));
      refSubmit.current.style.display = 'none';
      refApproveButton.current.disabled = false;
      refRejectButton.current.disabled = false;
      refRejectionWrapper.current.classList.remove('error-outline');
      refRejectionText.current.style.backgroundColor = "#fff";
      refRejectionError.current.style.display='none';
    }

    // Confirm Modal
    async function confirmOverlay(){
      let text = '<div class="center mt10">The following items have changed from their original value:</div>';
      let item;

      for(let i=0;i<Object.values(original).length;i++){
        if(Object.values(original)[i] !== Object.values(state)[i]){
          text += `<div class="changed-wrapper mt5">`
          text += `<div><strong>${Object.keys(original)[i]}:</strong></div>`
          // Build left side of = when empty/null
          
          if(!Object.values(original)[i]){
            if(Object.keys(original)[i] === 'image'){
              if(original.image === 'lahlouh'){
                text += '<div>Lahlouh Supplied'
              }else{
                text += '<div>None';
              }
            }else{
              text += '<div>empty ';
            }
          // Build left side of =
          }else{
            if(Object.keys(original)[i] === 'customer_number'){
              item = portals.filter(item => item.customer_number == Object.values(original)[i])
              text += '<div>' + item[0].name;
            }else if(Object.keys(original)[i] === 'image'){
              
              let split = original.image.split(',');
              if(split[0] === 'none'){
                text += 'None';
              }else if(split[0] === 'lahlouh'){
                text += 'Lahlouh Supplied';
              }else{
                text += split.length + ' image';
                if(split.length > 1) text += 's'
              }
            }else{
              text += `${Object.values(original)[i]}`
            }
          }
          // Build right side of =
          if(Object.keys(original)[i] === 'customer_number'){
            item = portals.filter(item => item.customer_number == Object.values(state)[i])
            text += ' <span>&#129054;</span> ' + item[0].name;
            text += '</div></div>';
          }else if(Object.keys(original)[i] === 'image'){
            // Lahlouh Supplied
            if(refLahlouh.current && refLahlouh.current.checked){
              text += ` <span>&#129054;</span> Lahlouh Supplied`;
              text += '</div></div>';
            }
            
            // New Images
            if(refNewImages.current && refNewImages.current.checked){
              if(refImage.current.files[0]){
                text += ' <span>&#129054;</span> ' + refImage.current.files.length + ' new image';
                if(refImage.current.files.length > 1) text += 's';
                text += '</div></div>';
              }
            }
            
            // No Images
            if(refNoImage.current && refNoImage.current.checked){
              text += ` <span>&#129054;</span> None`;
              text += '</div></div>';
            }
          }else{
            text += ` <span>&#129054;</span> ${Object.values(state)[i]}</div></div>`;
          }
        }
      }
      
      // Change from the internal object property names to a nicer display version
      text = text
      .replace('image', 'Image')
      .replace('inventory', 'Inventory')
      .replace('name', 'Name')
      .replace('item_type', 'Item Type')
      .replace('partnum', 'Part Number')
      .replace('append_partnum', 'Display Client Part Number?')
      .replace('effective_date', 'Effective Date')
      .replace('hold_date', 'Hold Date')
      .replace('next_day', 'Next Day')
      .replace('short_desc', 'Short Description')
      .replace('description', 'Long Description')
      .replace('display_price', 'Display Price')
      .replace('uom', 'Unit of Measure')
      .replace('display_uom', 'Display UOM?')
      .replace('orderable_qty', 'Orderable Quantities')
      .replace('re_order_point', 'Re-Order Point')
      .replace('category', 'Category')
      .replace('user_groups', 'User Groups')
      .replace('arrival_date', 'Arrival Date')
      .replace('expected_qty', 'Expected Quantity')
      .replace('batch_freq', 'Batch Frequency')
      .replace('approval_required', 'Approval Required')
      .replace('international', 'International Shipping')
      .replace('client_notes', 'Client Notes')
      .replace('vc_product_id', 'VeraCore Product ID')
      .replace('owner', 'Owner')
      .replace('job_number', 'Job Number')
      .replace('credit_price', 'Credit Price')
      .replace('estimate_number', 'Estimate Number')
      .replace('fix_notes', 'Fix Notes')
      .replace('customer_number', 'Portal')
  
      text += '</div>'
      text += '<div class="mt10 center">OK to submit with the above changes?</div>';
      
      let scrollbarWidth = window.innerWidth - document.body.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.marginRight = scrollbarWidth + 'px';

      popupConfirmContent.current.innerHTML = text;
      overlay.current.className = 'show';
      popupConfirm.current.className = 'show';
    }

    // Cancel Overlay
    async function cancelOverlay(){
      //let text = 'Hello there';
      
      let scrollbarWidth = window.innerWidth - document.body.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.marginRight = scrollbarWidth + 'px';

      //popupCancelContent.current.innerHTML = text;
      overlay.current.className = 'show';
      popupCancel.current.className = 'show';
    }

    // Informational Modal
    function infoModal(heading, content, autoclose = false, hideClose = false){
      if(hideClose) modalClose.current.style.display = 'none';
      
      modalHeading.current.innerHTML = heading;
      modalContent.current.innerHTML = content;
      let scrollbarWidth = window.innerWidth - document.body.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.marginRight = scrollbarWidth + 'px';
      overlay.current.className = 'show';
      popup.current.className = 'show';
      
      if(autoclose){
        modalClose.current.style.display = 'none';
          setTimeout(() => {
              document.body.style.overflow = "auto";
              document.body.style.marginRight = '0';
              overlay.current.className = '';
              popup.current.className = '';
          }, 2000)
      }else if (!autoclose && !hideClose){
          modalClose.current.style.display = 'block';
      }
    }

    // Modal functionality for label headings
    function showModal(e){
      e.preventDefault();
      modalHeading.current.innerHTML = e.target.innerHTML;
      modalContent.current.innerHTML = e.target.title.replace(/(?:\r\n|\r|\n)/g, '<p>');
      
      let scrollbarWidth = window.innerWidth - document.body.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.marginRight = scrollbarWidth + 'px';

      overlay.current.className = 'show';
      popup.current.className = 'show';
    }

    function showLoading(){
      let scrollbarWidth = window.innerWidth - document.body.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.marginRight = scrollbarWidth + 'px';
      loadingOverlay.current.className = 'show';
    }

    function hideLoading(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        loadingOverlay.current.className = '';
    }

    // Close popup - button  
    function closePopup(){
      document.body.style.overflow = "auto";
      document.body.style.marginRight = '0';
      if(overlay.current) overlay.current.className = '';
      if(popup.current) popup.current.className = '';
      if(popupConfirm.current) popupConfirm.current.className = '';
      if(popupCancel.current) popupCancel.current.className = '';
    }
    
    //Close popup - by clicking anywhere outside of it
    window.onclick = function(event) {
      if (event.target.id == 'overlay') {
          document.body.style.overflow = "auto";
          document.body.style.marginRight = '0';
          if(overlay.current) overlay.current.className = '';
          if(popup.current) popup.current.className = '';
          if(popupConfirm.current) popupConfirm.current.className = '';
          if(popupCancel.current) popupCancel.current.className = '';
      }
    }

    function scrollFunction() {
      if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
          if(refReturnTop.current){
              refReturnTop.current.style.display = "block";
          }
      } else {
          if(refReturnTop.current){
              refReturnTop.current.style.display = "none";
          }
      }
  }
  
  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  // Back to top scroll function
  window.onscroll = function() {scrollFunction()};

  async function cancelRequest(){
    if(cancel){
      showLoading();
      let requestObj = {request_status: 'Cancelled'}
      // Submit Update to PICDB
      let requestOptions = {
        method: 'PATCH',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestObj)
      };
    
      let response = await fetch('https://picform.lahlouhonline.com/api/requests/' + state.id, requestOptions)
      if(response.status === 403){
        hideLoading();
        infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
      }else{
        let data = await response.json()
        hideLoading();
          
        // Refresh state
        if(data.name){
          setState(prevState => ({
            ...prevState,
            request_status: 'Cancelled',
          }));
          infoModal("Cancelled", "This request has been cancelled", true)
        }
      }
    }else{
      cancelOverlay();
    }
  }

  function expandRelated(){
    if(refRelatedRequests.current.style.maxHeight){
      refRelatedRequests.current.style.maxHeight = null;
      refExpandRelated.current.classList.remove('active');
      refExpandRelated.current.title = 'Show related requests'
      
      refExpandArrow.current.className = 'expand-request-down';
    }else{
      refRelatedRequests.current.style.maxHeight = refRelatedRequests.current.scrollHeight + 'px';
      refExpandRelated.current.title = 'Hide related requests'
      refExpandRelated.current.classList.add('active');
      refExpandArrow.current.className = 'expand-request-up';
    }
  }

  function rowClick(e){
    let id = e.currentTarget.getAttribute('data-id')
    navigate('/request?id=' + id)
    navigate(0);
    
    //navigate('/requests')
    
  }

  if(state === null || (user.role === 'external' && user.username !== state.submitter_email)){
    return (
      <>
      {/* <div className="div-divider"></div> */}
      
      <div className="main">
        <div className="main-content">
          <h1 className="mt10">Request</h1>
          <div className="mt15 center"><h3 style={{fontWeight:'400'}}>No request to display.</h3></div>
        </div>
      </div>
      </>
    )
  } else{
    // Disabled Field Logic
    let isDisabled = false;
    
    // In Review - Only sales can edit
    if(state.request_status === 'In Review' && (user.role === 'external' || user.role === 'admin')) isDisabled = true;

    // In Progress / Completed
    if(state.request_status === 'In Progress' || state.request_status === 'Completed') isDisabled = true;
    
    // Fix - Only original submitter
    //if(state.request_status === 'Fix' && user.username !== state.submitter_email && user.role !== 'sales') isDisabled = true;
    // Fix - Only sales/admin
    if(state.request_status === 'Fix' && user.role === 'external') isDisabled = true;
    
    // Cancelled
    if(state.request_status === 'Cancelled') isDisabled = true;

    if(state.request_status === 'Pending'){
      if(user.username === state.submitter_email){
        isDisabled = false;
      }else{
        isDisabled = true;
      }
    }

    // Next Day Logic
    let nextDay = false;
    const todaysDate = new Date();
    todaysDate.setHours(0,0,0,0);

    // Grab entered effective date at midnight
    const eDate = new Date(state.effective_date + ' 00:00:00');

    // Add 1 business day to today's date
    const newDate = new Date(addBusinessDays(todaysDate, 1));
    
    // Date is 1 business day in the future
    if(eDate.getTime() === newDate.getTime()) nextDay = true;
    /* End of Next Day Logic */

    let client = null;
    if(portals && portals.length > 0){
        client = portals.filter(item => item.customer_number == state.customer_number)
    }

    return (
    <>
    <div className="main">
        <div className="main-content">
            <h1 className="mt10">Request</h1>

            <div className="mt20 center">
                Hover or click on a heading to see additional information when available.
            </div>

            {/* <div className="mt20 center" style={{fontSize:'18px',color:'red'}}>
              Role = {user.role}
            </div> */}

            {/* Request Header */}
            <div className="request-header slate-bg mt10">
              {/* Back to Requests Arrow */}
              <div className="back-to-requests">
                <Link to="/requests" title="Back to Requests" className="left-angle" />
              </div>

              {/* Request ID */}
              <div className="request-header-item">
                <strong>Request ID</strong>
                <div className="mt5">
                  {state.id}
                </div>
              </div>

              {/* Action */}
              <div className="request-header-item">
                <strong>Action</strong>
                <div className="mt5 center">
                  {state.action}
                </div>
              </div>

              {/* Status */}
              <div className="request-header-item">
                <strong>Status</strong>
                <div className="mt5 center">
                  {state.request_status === 'Fix' && <span className="highlight fix">{state.request_status}</span>}
                  {state.request_status === 'In Review' && <span className="highlight in-review">{state.request_status}</span>}
                  {state.request_status === 'In Progress' && <span className="highlight in-progress">{state.request_status}</span>}
                  {state.request_status === 'Completed' && <span className="highlight completed">{state.request_status}</span>}
                  {state.request_status === 'Received' && <span className="highlight received">{state.request_status}</span>}
                  {state.request_status === 'Cancelled' && <span className="highlight cancelled">{state.request_status}</span>}
                  {state.request_status === 'Pending' && <span className="highlight dark-draft" title="Request is ready to be reviewed and submitted">{state.request_status}</span>}
                </div>
              </div>
            </div>
            
            <div className={user.role === 'admin' || user.role === 'sales' ? "form-top" : "form-bottom"}>
                {/* Cancel Request Button */}
                {state.request_status === 'Received' && (user.username === state.submitter_email || user.role === 'admin') &&
                <div className="element center mt10">
                  <button 
                    className="cancel-button" 
                    title="Cancel Request"
                    onClick={cancelRequest}
                  >Cancel Request</button>
                </div>
                }

                {state.request_status === 'Cancelled' &&
                <div className="element center mt10" style={{color:'red',fontWeight:'bold'}}>
                  * DO NOT PROCESS *
                </div>
                }

                {/* View Associated Jira Button */}
                {user.role !== 'external' && state.jira_id !== '' && state.jira_id != null && 
                  <div className="center mt10">
                    <a href={"https://lahlouh.atlassian.net/browse/" + state.jira_id} target="_blank" title="View associated JIRA Ticket">
                      <button className="admin-button">View Associated Jira Ticket</button>
                    </a>
                  </div>
                }

                {/* Group Requests */}
                {slaveRequests && slaveRequests.length > 0 &&
                <>
                <div 
                  onClick={expandRelated}
                  className="expand-requests"
                  ref={refExpandRelated}
                  title="Show related requests"
                >
                  <span ref={refExpandArrow} className="expand-request-down"></span>Related Requests ({slaveRequests && slaveRequests.length > 0 && slaveRequests.length})
                  
                </div>
                {/* Related Requests */}
                <div ref={refRelatedRequests} className="slave-requests">
                  <table cellPadding="0" cellSpacing="0" border="0">
                    <tbody>
                    {slaveRequests && slaveRequests.length > 0 && slaveRequests.map((item, index) => 
                      <tr 
                        title="View Request"
                        onClick={rowClick}
                        key={index}
                        data-id={item.id}
                      >
                        <td>{item.partnum}</td>
                        <td>{item.name}</td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
                </>
                }

                {/* <div className="group-flexbox">
                  <div className="left-group">
                    {state.group_request.split('-')[1] !== '1' && prevRequest
                    ? <a href={"/request?id=" + prevRequest[0].id} title="Previous request in group" className="left-group-angle" ></a>
                    : <span className="left-group-angle disabled" />}

                  </div>
                  <div className="center">
                    <strong className="group-header">Group Request</strong>&nbsp;
                    <div className="group-count">
                      <strong>{state.group_request.split('-')[1]}</strong> of {slaveRequests && slaveRequests.length > 0 ? slaveRequests.length: ''}
                    </div>
                  </div>
                  <div className="right-group">
                    {(parseInt(state.group_request.split('-')[1]) != (totalNum+1)) && nextRequest
                      ? <a href={"/request?id=" + nextRequest[0].id} title="Next request in group" className="right-group-angle" ></a>
                      : <span className="right-group-angle disabled" />}
                  </div>
                </div> */}
                {/* </> */}
                {/* } */}

                <div className="internal-view">
                  {/* Date Submitted */}
                  <div>
                    <label>Date Submitted</label>
                    <div className="mt5">
                      {formatDate(new Date(state.date_submitted))}
                      {user.role !== 'external' && 
                      <div className="mt5">
                        {state.submitter_email}
                      </div>
                      }
                    </div>
                  </div>
                 
                  {/* Date Approved */}
                  {state.date_approved && user.role !== 'external' && 
                    <div className="mt15">
                      <label>Date Approved</label>
                      <div className="mt5">
                        {formatSimpleDate(new Date(state.date_approved.replace(/-/g, '\/').replace(/T.+/, '')))}
                      </div>
                      <div className="mt5">
                        {state.approver_email}
                      </div>
                    </div>
                  }
                </div>

                {/* Fix Notes */}
                {((state.request_status === 'Fix') || user.role === 'admin') && (state.request_status !== 'In Progress' && state.request_status !== 'Completed' && state.fix_notes != '') &&
                <>
                  <div className="div-fix-notes mt10 center">
                    <h3 className="red-text">Fix Notes</h3>
                    <div className="mt5">
                      {state.fix_notes}
                    </div>
                  </div>

                  <div className="thin-divider mt10 mb10"></div>
                  
                  </>
                }

                {/* Portal Selection */}
                {user.role !== 'external' &&
                <div className="element">
                    <label className="required">Portal Selection</label>

                    <select 
                        name="customer_number"
                        ref={refPortal}
                        onChange={handleChange}
                        disabled={isDisabled}
                    >
                        <option>Select an option...</option>
                    </select>

                    <div ref={refPortalError} className="errorMessage">
                        Please select a portal.
                    </div>
                </div>
                }

                {/* Product Images */}
                {state.action.indexOf('Remove') === -1 &&
                
                <>
                <div className="small-width mt14">
                  <label title="Any images that will be displayed on the portal. Standard default is to display the front page or front panel of the final image. For specific ordering, please name your files accordingly. No PDF format." onClick={event => showModal(event)}>Product Images</label>
                </div>
                
                {original.image !== 'lahlouh' && original.image !== 'none' &&
                <div className="small-width">
                  <label className="radio-container flex" style={isDisabled ? {marginLeft:'-18px',marginBottom:'0'} : {marginBottom:'0'}}>
                    {!isDisabled &&
                    <>
                    <input 
                        type="radio"
                        name="image"
                        id="image-attach"
                        //checked={state.image === 'attach'}
                        checked={state.image !== 'newimages' && state.image !== 'lahlouh' && state.image !== 'none'}
                        value={original.image}
                        onChange={handleChange}
                        ref={refAttach}
                        disabled={isDisabled}
                    />
                    <span className="checkmark"></span>
                    </>
                    }

                    <div className={original.image.split(',').length === 0 ? "flex-row" : "flex-row flex-left"}>
                
                      {original.image && original.image.split(',').length > 0 && original.image.split(',').map((item, index) => 
                      (
                      <div key={index} className="product-image-wrapper" >
                        <a href={"/product-images/" + item} target="_blank" title="View full size image in another tab"><img src={process.env.PUBLIC_URL + "/product-images/" + item} /></a>
                      </div>    
                      )
                      )}
                    
                    </div>
                  </label>
                </div>
                }

                {((state.request_status === 'Fix' && user.username === state.submitter_email) || state.request_status === 'Received' || (user.role === 'sales' && state.request_status === 'In Review')) &&
                <>
                <div className="small-width">
                {/* {original.image && <label className="mt5" title="Replace the images that are attached to this request">Upload New Images</label>} */}
                {/* {!original.image && <label className="mt5" title="Any images that will be displayed on the portal. Standard default is to display the front page or front panel of the final image. For specific ordering, please name your files accordingly. No PDF format." onClick={event => showModal(event)}>Upload Images</label>} */}
                <label className="radio-container flex">
                  <input 
                      type="radio"
                      name="image"
                      id="image-attach"
                      checked={state.image === 'newimages'}
                      value="newimages"
                      onChange={handleChange}
                      ref={refNewImages}
                      disabled={isDisabled}
                  />
                  <span className="checkmark"></span>

                  <div 
                      className="file-button"
                      style={{marginTop:'3px'}}
                      onClick={() => {
                        //refImageDetails.current.innerHTML = 'Loading...'
                        refImage.current.click()
                      }}
                      title="Attach file(s)"
                  ><img src="./assets/images/upload2.png" />&nbsp;Choose Files</div>
                  <span ref={refImageDetails}>
                    Upload/Replace Images...
                  </span>
                  <input 
                      name="image"
                      ref={refImage}
                      className="hide"
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      multiple={true}
                      onChange={updateImageDetails}
                  />
                </label>
                </div>

                <div ref={refImageError} className="small-width errorMessage" style={{marginTop:'-8px',marginBottom:'8px'}}></div>
                </>
                }
                </>
                }
                
                {/* Lahlouh Supplied Image */}
                {((isDisabled && state.image === 'lahlouh') || !isDisabled) && state.action.indexOf('Remove') === -1 && state.action.indexOf('Update') === -1 &&
                <div className="small-width m15">
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="image"
                            id="image-lahlouh"
                            checked={state.image === 'lahlouh'}
                            value="lahlouh"
                            onChange={handleChange}
                            ref={refLahlouh}
                            disabled={isDisabled}
                        />
                        <span className="checkmark"></span>
                        Lahlouh Supplied Image
                    </label>
                </div>
                }
                
                {/* No Image Needed */}
                {((isDisabled && state.image === 'none') || !isDisabled) && state.action.indexOf('Remove') === -1 && state.action.indexOf('Update') === -1 &&
                <div className="small-width m15">
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="image"
                            id="image-none"
                            checked={state.image === 'none'}
                            value="none"
                            onChange={handleChange}
                            ref={refNoImage}
                            disabled={isDisabled}
                        />
                        <span className="checkmark"></span>
                        
                        No Image Needed
                    </label>
                </div>
                }

                {/* Inventory */}
                {state.action.indexOf('Remove') !== -1 &&
                  <div className="element">
                      <label onClick={event => showModal(event)}>Inventory</label>
                        <input
                          type="text"
                          name="inventory" 
                          value={state.inventory ? state.inventory : ''}
                          onChange={handleChange}
                          disabled={true}
                        />
                  </div>
                }
                
                {/* Client Part Number */}
                <div className="element">
                    <label className="required" title="This is the number by which the client refers to the part. Ideally, it is shown on the part and is the same as the VeraCore Product ID. No special characters, only dashes allowed." onClick={event => showModal(event)}>Client Part No. (SKU)</label>
                      <input
                        type="text"
                        ref={refPartNum}
                        name="partnum"
                        value={state.partnum ? state.partnum : ''}
                        onChange={handleChange}
                        disabled={isDisabled}
                      />
                      <div ref={refPartNumError} className="errorMessage"></div>
                </div>

                {/* Display Client Part No. (SKU)? */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' && state.request_status !== 'In Progress' && state.request_status !== 'Completed' &&
                <div className="radio-group">
                    <label title="Indicates whether the Client Part No. (SKU) should be appended onto the end of the product title on the site." onClick={event => showModal(event)}>
                      Display Client Part No. (SKU)?
                    </label>
                    
                    {/* Append Part Number = Yes */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="append_partnum"
                            id="append-partnum-yes"
                            checked={state.append_partnum === 'yes'}
                            value="yes"
                            disabled={isDisabled}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Yes
                    </label>

                    {/* Append Part Number = No */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="append_partnum"
                            id="append-partnum-no"
                            checked={state.append_partnum === 'no'}
                            value="no"
                            disabled={isDisabled}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        No
                    </label>
                </div>
                }

                {/* Display Client Part Number */}
                {(state.request_status === 'Completed' || state.request_status === 'In Progress') && state.approval_required !== null && state.approval_required !== "" &&
                <div className="radio-group">
                    <label title="Indicates whether the Client Part No. (SKU) should be appended onto the end of the product title on the site." onClick={event => showModal(event)}>
                      Display Client Part No. (SKU)?
                    </label>
                    <div className="pad-wrapper">
                      {state.append_partnum}    
                    </div>
                </div>
                }
                
                {/* Product Title */}
                <div className="element">
                    <label className="required" title="Enter the product title you would like displayed on the portal." onClick={event => showModal(event)}>
                      Product Title
                    </label>
                    <input
                      type="text"
                      ref={refName}
                      name="name" 
                      value={state.name ? state.name : ''}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />
                    <div ref={refNameError} className="errorMessage">
                        Please enter a product title.
                    </div>
                </div>

                {/* Product Short Description */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' &&
                  <div className="element">
                      <label title="Optional: You can add product detail notes which will display on the Catalog Page (165 character limit)." onClick={event => showModal(event)}>
                        Product Short Description
                    </label>
                      <div className="textarea-wrapper">
                        <textarea
                            name="short_desc"
                            className="short-desc"
                            value={state.short_desc ? state.short_desc : ''}
                            onChange={handleChange}
                            maxLength={165}
                            disabled={isDisabled}
                        />
                      </div>
                  </div>
                }
                
                {/* Product Long Description */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' &&
                  <div className="element">
                      <label title="Optional: You can add product detail notes which will display on the Product Details Page." onClick={event => showModal(event)}>
                        Product Long Description
                    </label>
                      <div className="textarea-wrapper">
                        <textarea
                            name="description"
                            value={state.description ? state.description : ''}
                            onChange={handleChange}
                            disabled={isDisabled}
                        />
                      </div>
                  </div>
                }

                {/* Display Price */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' &&
                <div className="element">
                <label title="This is an optional field to confirm the product price displayed to the end user." onClick={event => showModal(event)}>
                        Display Price
                    </label>
                    <input
                      type="text"
                      ref={refDisplayPrice}
                      name="display_price" 
                      value={state.display_price ? state.display_price : ''}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />
                    <div ref={refDisplayPriceError} className="errorMessage">
                        
                    </div>
                </div>
                }

                {/* Unit of Measure (UOM) */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' &&
                  <div className="element">
                    <label className={state.action.indexOf('Add') !== -1 ? "required" : undefined} title="Unit of Measure (UOM) refers to the quantity that the item is warehoused in, i.e., 1/Each, Packs of 5, Packs of 10, etc." onClick={event => showModal(event)}>Unit of Measure (UOM)</label>
                    <input
                      type="text"
                      name="uom"
                      ref={refUOM}
                      value={state.uom ? state.uom : ''}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />

                    <div ref={refUOMError} className="errorMessage">
                        Please enter a unit of measure.
                    </div>
                  </div>
                }

                {/* Display UOM? */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' && state.request_status !== 'In Progress' && state.request_status !== 'Completed' &&
                <div className="radio-group">
                    <label title='Default is to display "Yes", if not wanted, click "No".' onClick={event => showModal(event)}>
                      Display UOM?
                    </label>
                    
                    {/* Display UOM = Yes */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="display_uom"
                            id="display-uom-yes"
                            checked={state.display_uom === 'yes'}
                            value="yes"
                            disabled={isDisabled}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Yes
                    </label>

                    {/* Display UOM = No */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="display_uom"
                            id="display-uom-no"
                            checked={state.display_uom === 'no'}
                            value="no"
                            disabled={isDisabled}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        No
                    </label>
                </div>
                }

                {/* Orderable Quantities */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' &&
                  <div className="element">
                      <label 
                        className={state.action.indexOf('Add') !== -1 ? "required" : undefined} 
                        title='"Unlimited" - Free field entry, no minimum or maximum order qty&#013;&#010;Range - Ex: "1-50" - Free field entry, minimum 1, maximum 50 order qty&#013;&#010;Single Number - Ex: "1" - Dropdown with a single, specific orderable quantity&#013;&#010;Comma Separated - Ex: "5,10,15" - Dropdown selections with specific orderable quantities&#013;&#010;Comma Separated with pattern – Ex: "5,10,15...50" – Dropdown with selections up to a max – pattern indicated before "..."'
                        onClick={event => showModal(event)}
                      >
                        Orderable Quantities (by UOM)
                      </label>
                      
                      <input
                        type="text"
                        name="orderable_qty"
                        ref={refOrderQty}
                        value={state.orderable_qty ? state.orderable_qty : ''}
                        onChange={handleChange}
                        disabled={isDisabled}
                      />
                      <div ref={refOrderQtyError} className="errorMessage">
                        Please enter any orderable quantities.
                      </div>
                  </div>
                }

                {/* Re-Order Point */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' &&
                  <div className="element">
                      <label title="Indicate the quantity on hand at which you want to receive an automated re-order reminder email. Keep in mind the time it takes to produce the reprint." onClick={event => showModal(event)}>
                          Re-Order Point (by UOM)</label>
                      <input
                        type="text"
                        name="re_order_point"
                        value={state.re_order_point ? state.re_order_point : ''}
                        onChange={handleChange}
                        disabled={isDisabled}
                      />
                  </div>
                }

                {/* User Group */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' &&
                  <div className="element">
                      <label 
                        className={state.action.indexOf('Add') !== -1  && user.role !== "external" ? "required" : undefined} 
                        title="This refers to which division, business unit, or user group(s) have visibility to the item on the portal.  Include all groups if more than one." 
                        onClick={event => showModal(event)}
                      >
                        User Group(s)
                      </label>
                      <input
                        type="text"
                        name="user_groups"
                        ref={refUserGroup}
                        value={state.user_groups ? state.user_groups : ''}
                        onChange={handleChange}
                        disabled={isDisabled}
                      />
                      <div ref={refUserGroupError} className="errorMessage">
                        Please enter any associated user groups.
                      </div>
                  </div>
                }
                
                {/* Product Category */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' &&
                  <div className="element">
                      <label className={state.action.indexOf('Add') !== -1 ? "required" : undefined} title="This refers to where the product is placed on the portal. Common groupings include: Brand, Product Type, etc." onClick={event => showModal(event)}>
                        Product Category
                      </label>
                      <input
                        type="text"
                        name="category"
                        ref={refCategory}
                        value={state.category ? state.category : ''}
                        onChange={handleChange}
                        disabled={isDisabled}
                      />
                      <div ref={refCategoryError} className="errorMessage">
                        Please enter the product category.
                      </div>
                  </div>
                }

                {/* Expected Arrival Date */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' &&
                  <div className="element">
                      <label className={state.action.indexOf('Add') !== -1 && user.role !== "external" ? "required" : undefined} title="This is the date the items are scheduled to arrive at the Lahlouh warehouse." onClick={event => showModal(event)}>Expected Arrival Date</label>
                      <input
                          type="date"
                          name="arrival_date"
                          ref={refArrivalDate}
                          value={state.arrival_date}
                          placeholder="YYYY-MM-DD"
                          onChange={handleChange}
                          disabled={isDisabled}
                      />

                      <div ref={refArrivalDateError} className="errorMessage">
                          Please enter the expected arrival date.
                      </div>
                  </div>
                }

                {/* Expected Quantity */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' &&
                  <div className="element">
                    <label className={state.action.indexOf('Add') !== -1 && user.role !== "external" ? "required" : undefined} title="Please provide expected quantity if product will be shipping to Lahlouh's warehouse." onClick={event => showModal(event)}>Expected Quantity</label>
                    <input
                      type="text"
                      name="expected_qty"
                      ref={refExpectedQty}
                      value={state.expected_qty ? state.expected_qty : ''}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />

                    <div ref={refExpectedQtyError} className="errorMessage">
                        Please enter the expected quantity.
                    </div>
                  </div>
                }

                {/* Batch Frequency */}
                {state.action !== 'Remove Item' && state.action !== 'Image Update' &&
                <div className="element">
                    <label title="If item will be printed on demand (POD), please indicate if you prefer items to be printed in batches and if so, at what frequency." onClick={event => showModal(event)}>
                        Batch Frequency (POD Items)
                    </label> 
                    <select 
                        name="batch_freq"
                        ref={refBatchFreq}
                        value={state.batch_freq} 
                        onChange={handleChange}
                        disabled={isDisabled}
                    >
                      <option value=''>Select an option...</option>
                      <option>No Batching</option>
                      <option>Every Monday</option>
                      <option>Every Tuesday</option>
                      <option>Every Wednesday</option>
                      <option>Every Thursday</option>
                      <option>Every Friday</option>
                      <option>Other</option>
                    </select>
                </div>
                }
                
                {/* Item Type */}
                {(state.action.indexOf('Remove') === -1 && state.action !== 'Image Update') && (state.request_status !== 'In Progress' && state.request_status !== 'Completed') &&
                <>
                <div className="radio-group">
                    <label>Item Type</label>
                    {/* Food */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-food"
                            checked={state.item_type === 'food'}
                            value="food"
                            disabled={isDisabled}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Food Items</strong> - Cannot ship internationally. Please provide expiration dates and lot numbers in special instructions.
                    </label>

                    {/* Agricultural or Sanitizer */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-ag-or-sanitizer"
                            checked={state.item_type === 'agricultural-or-sanitizer'}
                            value="agricultural-or-sanitizer"
                            disabled={isDisabled}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Agricultural Items & Sanitizer</strong> - Cannot ship internationally.
                    </label>

                    {/* Contains Batteries */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-contains-batteries"
                            checked={state.item_type === 'contains-batteries'}
                            value="contains-batteries"
                            disabled={isDisabled}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Items with Batteries</strong> - Subject to shipping restrictions and special labeling requirements.
                    </label>

                    {/* Face Mask */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-face-mask"
                            checked={state.item_type === 'face-mask'}
                            value="face-mask"
                            disabled={isDisabled}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Face Masks</strong> - Subject to international shipping restrictions by country.
                    </label>

                    {/* Other */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-other"
                            checked={state.item_type === 'other'}
                            value="other"
                            disabled={isDisabled}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Other</strong>
                    </label>
                </div>

                <div className="thin-divider"></div>
                </>
                }
                
                {(state.request_status === 'Completed' || state.request_status === 'In Progress') && state.item_type !== null && state.item_type !== "" &&
                  <div className="radio-group">
                    <label>Item Type</label>
                    <div className="pad-wrapper">
                      {state.item_type}
                    </div>
                  </div>
                }
                
                {/* Approvals Required? */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' && state.request_status !== 'In Progress' && state.request_status !== 'Completed' &&
                <>
                <div className="radio-group">
                    <label title="Indicate if an approval is required when this item is ordered.  Add instructions in the product notes field if needed." onClick={event => showModal(event)}>Order Approvals Required?</label>
                    
                    {/* Approval Required = Yes */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="approval_required"
                            id="approval-yes"
                            checked={state.approval_required === 'yes'}
                            value="yes"
                            disabled={isDisabled}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Yes
                    </label>

                    {/* Approval Required = No */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="approval_required"
                            id="approval-no"
                            checked={state.approval_required === 'no'}
                            value="no"
                            disabled={isDisabled}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        No
                    </label>
                </div>

                <div className="thin-divider"></div>
                </>
                }
                {(state.request_status === 'Completed' || state.request_status === 'In Progress') && state.approval_required !== null && state.approval_required !== "" &&
                <div className="radio-group">
                    <label title="Indicate if an approval is required when this item is ordered.  Add instructions in the comments field if needed." onClick={event => showModal(event)}>Order Approvals Required?</label>
                    <div className="pad-wrapper">
                      {state.approval_required}    
                    </div>
                </div>
                }

                {/* International Shipping? */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' && state.request_status !== 'In Progress' && state.request_status !== 'Completed' &&
                <div className="radio-group">
                    <label title="If international shipping is needed please include item value ($) & country of origin in the comments field." onClick={event => showModal(event)}>International Shipping?</label>
                    
                    {/* International Shipping = Yes */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="international"
                            id="international-yes"
                            checked={state.international === 'yes'}
                            value="yes"
                            disabled={isDisabled}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Yes
                    </label>

                    {/* International Shipping = No */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="international"
                            id="international-no"
                            checked={state.international === 'no'}
                            value="no"
                            disabled={isDisabled}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        No
                    </label>
                </div>
                }
                {(state.request_status === 'Completed' || state.request_status === 'In Progress') && state.international !== null && state.international !== "" &&
                <div className="radio-group">
                    <label title="If international shipping is needed please include item value ($) & country of origin in the comments field." onClick={event => showModal(event)}>International Shipping?</label>
                    <div className="pad-wrapper">
                      {state.international}    
                    </div>
                </div>
                }

                {/* Effective Date */}
                <div className="element">
                    <label className={user.role !== "external" ? "required" : undefined} title="Change could go live sooner than this date depending on the teams workload. Check the 'Hold until effective date' checkbox if needed." onClick={event => showModal(event)}>Effective Date</label>
                    <input 
                        type="date"
                        name="effective_date"
                        ref={refDate}
                        value={state.effective_date}
                        placeholder="YYYY-MM-DD"
                        onChange={handleChange}
                        disabled={isDisabled}
                    />

                    <div ref={refDateError} className="errorMessage"></div>
                    
                    
                    {/* Next Day Checkbox */}
                    
                    <div ref={refDateCharge} className={(state.next_day === 'yes') || (state.request_status === 'Pending' && nextDay) || (state.request_status === 'Received' && nextDay) ? "radio-check-wrapper errorMessage show" : "radio-check-wrapper errorMessage"}>
                      <label className="checkbox-container no-highlight mt5">
                            <input 
                                ref={refNextDay}
                                type="checkbox"
                                name="next-day-check"
                                id="next-day"
                                //value={state.next_day}
                                onChange={handleChange}
                                checked={state.next_day === 'yes'}
                                disabled={isDisabled}
                            />
                            <span className="checkmark"></span>
                            Next day service may incur an additional charge.
                        </label>
                    </div>
  
                    {/* Hold Until Date Checkbox */}
                    {state.hold_date &&
                    <div className="radio-check-wrapper errorMessage" style={{display:'flex'}}>
                      <label className="checkbox-container no-highlight mt5">
                            <input 
                                ref={refHoldDate}
                                type="checkbox"
                                name="hold-date-check"
                                id="hold-date"
                                //value={state.hold_date === 'yes' ? 'no' : 'yes'}
                                //value="yes"
                                onChange={handleChange}
                                checked={state.hold_date === 'yes'}
                                disabled={isDisabled}
                            />
                            <span className="checkmark"></span>
                            Hold until effective date
                        </label>
                    </div>
                    }
                </div>

                {/* Product Notes */}
                <div className="element">
                <label title="Enter any specific actions or changes related to this product. Description, placement on the site, etc. Include any details that are needed to help aid the actions to be taken." onClick={event => showModal(event)}>
                        Additional Product Notes
                    </label>
                    <div className="textarea-wrapper">
                      <textarea
                        name="client_notes"
                        value={state.client_notes ? state.client_notes : ''}
                        onChange={handleChange}
                        disabled={isDisabled}
                      />
                    </div>
                </div>
            </div>

            {(user.role === 'admin' || user.role === 'sales') &&
            <div ref={refInternal} className="internal-content">
              <div className="form-divider yellow-bg center">
                Lahlouh Internal
              </div>

              {/* <h2 className="center">Lahlouh Details</h2> */}
              
              <div className="admin-form-bottom">
                

                {/* VeraCore Owner */}
                <div className="element">
                    <label 
                      className="required" 
                      title="Enter the VeraCore owner info for this particular product." 
                      onClick={event => showModal(event)}
                    >
                      VeraCore Owner
                    </label> 
                    <input
                      type="text"
                      ref={refOwner}
                      name="owner"
                      value={state.owner ? state.owner : ''}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />

                    <div ref={refOwnerError} className="errorMessage">
                        Please enter the VeraCore owner.
                    </div>
                </div>

                {/* VeraCore Product ID */}
                <div className="element">
                    <label className="required" title="This is the product ID that is assigned by Lahlouh. Ideally, it is the same as the Client Part No. (SKU) and should be included on the physical inventory item. It is included on all carton labels." onClick={event => showModal(event)}>
                        VeraCore Product ID #
                    </label> 
                    <input
                      type="text"
                      ref={refVeraCore}
                      name="vc_product_id"
                      value={state.vc_product_id ? state.vc_product_id : ''}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />

                    <div ref={refVeraCoreError} className="errorMessage">
                        Please enter the VeraCore Product ID.
                    </div>
                </div>
                
                {/* Job Number */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' &&
                <div className="element">
                    <label title="Only required if product is in production at the time this form is submitted." onClick={event => showModal(event)}>
                        Lahlouh Job #
                    </label> 
                    <input
                      type="text"
                      name="job_number"
                      value={state.job_number ? state.job_number : ''}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />
                </div>
                }

                {/* PACE Template # */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' &&
                <div className="element">
                    <label title="Only required with print-on-demand (POD) items." onClick={event => showModal(event)}>
                      PACE Template # (for POD Items)
                    </label> 
                    <input
                      type="text"
                      name="estimate_number"
                      ref={refEstimate}
                      value={state.estimate_number ? state.estimate_number : ''}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />

                    <div ref={refEstimateError} className="errorMessage">
                        Please enter a PACE Template #.
                    </div>
                </div>
                }

                {/* Credit Price */}
                {state.action.indexOf('Remove') === -1 && state.action !== 'Image Update' &&
                <div className="element">
                    <label title="Cost of product that will need to be reimbursed or credit given per unit." onClick={event => showModal(event)}>
                      Reimbursement or Credit Price
                    </label> 
                    <input
                      type="text"
                      name="credit_price"
                      ref={refCreditPrice}
                      value={state.credit_price ? state.credit_price : ''}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />

                    <div ref={refCreditPriceError} className="errorMessage">
                        Please enter a customer number.
                    </div>
                </div>
                }

                {/* Billing Price */}
                <div className="element hide">
                    <label 
                        title="Enter the total billing cost, including new user groups and/or product categories." 
                        onClick={event => showModal(event)}
                        className="required"
                    >
                      Billing Price
                    </label> 
                    <input
                      type="text"
                      name="bill_price"
                      ref={refBillPrice}
                      value={state.bill_price}
                      onChange={handleChange}
                    />

                    <div ref={refBillPriceError} className="errorMessage"></div>

                    <div className="element-note-wrapper">
                        <div>
                            New Category - Yes
                        </div>

                        <div>
                            New User Group - No
                        </div>
                        
                        {/* {state.group_request && state.group_request !== null && state.group_request !== '' && */}
                        <div>
                            This request is part of a group - Be sure to capture the added billing price for only <i>one</i> of the requests.
                        </div>
                    </div>
                </div>

                <div ref={refJiraNotes} className="element jira-notes">
                    <div className="jira-divider"></div>
                    <div className="center mt10">
                        <strong className="jira-heading">Jira Notes</strong>
                    </div>
                    {client && client.length > 0 &&
                    <div className="mt10 center">
                        <a href={"https://lahlouh.atlassian.net/browse/" + client[0].epic} target="_blank" title="View JIRA Maintenance Ticket">
                            <button className="rounded-button">View Maintenance Jira</button>
                        </a>
                    </div>
                    }
                    <div ref={refJiraNote} className="jira-note"/>
                </div>
              </div>
            </div>
            }

            <div ref={refErrors} className="small-width errorMaster center">
                There are items that require your attention above
            </div>
            
            {/* Admin Submit / Reject - When status = Received */}
            {user.role === 'admin' && state.request_status === 'Received' &&
              <div className="mt20 center">
                  <button ref={refApproveButton} className="approve-button" onClick={() => {handleSubmit()}} title="Approve Request">&#10003; Approve</button>
                  <button ref={refRejectButton} className="reject-button" onClick={showRejection} title="Reject Request">Reject</button>
              </div>
            }
            
            {/* Fix Notes - For Rejection of Form */}
            <div ref={refRejection} className="mt20 element hide">
              <h2 className="center">Why did you reject?</h2>
              <label className="mt10 required" title="Enter the reason why the request was rejected. Be specific about what the submitter will need to fix." onClick={event => showModal(event)}>Rejection Notes</label>
              <div ref={refRejectionWrapper} className="textarea-wrapper">
                <textarea
                  ref={refRejectionText}
                  name="fix_notes"
                  value={state.fix_notes ? state.fix_notes : ''}
                  onChange={handleChange}
                />
              </div>
              <div ref={refRejectionError} className="errorMessage">
                  Enter some details on why the request is being rejected.
              </div>
            </div>

            {/* Submit Buttons for Sales/External*/}
            {(state.request_status === 'Fix' && (user.role === 'sales' || user.role === 'admin')) || ((user.role === 'sales' || user.role === 'external') && state.request_status === 'Received')
              ? 
              // Fix Re-Submit / Sales/External Re-Submit
              <div ref={refSubmit} className="mt20 center">
                  <button className="primary-button" onClick={() => {handleSubmit()}} title="Submit">Update</button>
              </div>
              :
              // Admin Submit Rejection / Cancel Rejection
              <div ref={refSubmit} className="mt20 center hide">
                  <button className="primary-button" onClick={() => {handleSubmit()}} title="Submit">Submit</button>
                  <button ref={refCancelRejection} className="reject-button hide" onClick={cancelReject} title="Cancel Rejection">Cancel Reject</button>
              </div>
            }

            {/* Pending Re-Submit - By Original Submitter */}
            {state.request_status === 'Pending' && state.submitter_email === user.username &&
            <div ref={refSubmit} className="mt20 center">
              <button className="primary-button" onClick={() => {handleSubmit()}} title="Update">Update</button>
            </div>
            }

            {/* In Review - Sales Submit */}
            {state.request_status === 'In Review' && user.role === 'sales' &&
            <div ref={refSubmit} className="mt20 center">
              <button className="primary-button" onClick={() => {handleSubmit()}} title="Update / Confirm">Update / Confirm</button>
            </div>
            }
        </div>
    </div>

    <div ref={refReturnTop} className="return-top" onClick={topFunction}>
      &#x25B2; Return to top
    </div>

    <div id="overlay" ref={overlay}></div>

    <div id="loading-overlay" ref={loadingOverlay}>
        <div className="deadCenter">
            <div className="lds-spinner">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
        </div>
    </div>

    <div id="popup" ref={popup}>
        <h2 id="modal-heading" ref={modalHeading} className="center">Heading</h2>
        <span id="popup-close" ref={modalClose} className="close" onClick={closePopup}>&times;</span>
        <div id="modal-content" ref={modalContent} className="content center">
          Text changed through JS
        </div>
    </div>

    <div id="popup-confirm" ref={popupConfirm}>
        <h2 id="modal-heading" ref={popupConfirmHeading} style={{textAlign:'center'}}>Confirm Changes</h2>
        <span className="close" onClick={closePopup}>&times;</span>
        <div id="modal-content" ref={popupConfirmContent} className="content">
          Text changed through JS
        </div>
        <div className="mt10 center">
          <button className="primary-button smaller-button" onClick={(e) => {closePopup();setConfirm(true)}} title="Yes">Yes</button>
          <button className="secondary-button smaller-button" onClick={() => {closePopup();setConfirm(false)}} title="No">No</button>
        </div>
    </div>

    <div id="popup-cancel" ref={popupCancel}>
        <h2 id="modal-heading" ref={popupCancelHeading} style={{textAlign:'center'}}>Cancel Request</h2>
        <span className="close" onClick={closePopup}>&times;</span>
        <div id="modal-content" ref={popupCancelContent} className="content center mt10">
          This action will cancel the current request.
          <div className="mt10">
            Are you sure?
          </div>
        </div>
        <div className="mt10 center">
          <button className="primary-button smaller-button" onClick={(e) => {closePopup();setCancel(true)}} title="Yes">Yes</button>
          <button className="secondary-button smaller-button" onClick={() => {closePopup();setCancel(false)}} title="No">No</button>
        </div>
    </div>
    </>
  )
  }
}
