import React, { useState, useRef, useEffect, useContext } from 'react'
import { UserContext } from '../context/UserContext';
import { useNavigate } from "react-router-dom";
import { formatDatePicker } from '../Utils/utils'
import { parse } from 'url';

export default function AddChange() {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [confirm, setConfirm] = useState(null)
    // Does logged in user have pending requests or not
    const [requests, setRequests] = useState(null)
    const [pending, setPending] = useState(false)
    const [pendingRequests, setPendingRequests] = useState(null);
    const [copiedProduct, setCopiedProduct] = useState(false);
    const [original, setOriginal] = useState(null);
    const [copiedImage, setCopiedImage] = useState('');
    const [clients, setClients] = useState(null);
    
    let companyName = null;

    // DOM References
    const refAction = useRef();
    const refActionError = useRef();
    const refPartNum = useRef();
    const refPartNumError = useRef();
    const refName = useRef();
    const refNameError = useRef();
    const refArrivalDate = useRef();
    const refArrivalDateError = useRef();
    const refDate = useRef();
    const refDateError = useRef();
    const refDateCharge = useRef();
    const refDisplayPrice = useRef();
    const refDisplayPriceError = useRef();
    const refCreditPrice = useRef();
    const refCreditPriceError = useRef();
    const refReturnTop = useRef();
    const refNextDay = useRef();
    const refImage = useRef();
    const refImageError = useRef();
    const refPortal = useRef();
    const refPortalError = useRef();
    const refAttach = useRef();
    const refLahlouh = useRef();
    const refNoImage = useRef();
    const refErrors = useRef();
    const refPortalList = useRef();
    const refImageDetails = useRef();
    const refVeraCore = useRef();
    const refVeraCoreError = useRef();
    const refOwner = useRef();
    const refOwnerError = useRef();
    const refSelectCopy = useRef();
    const refUOM = useRef();
    const refUOMError = useRef();
    const refOrderQty = useRef();
    const refOrderQtyError = useRef();
    const refReOrderPoint = useRef();
    const refReOrderPointError = useRef();
    const refUserGroup = useRef();
    const refUserGroupError = useRef();
    const refCategory = useRef();
    const refCategoryError = useRef();
    const refExpectedQty = useRef();
    const refExpectedQtyError = useRef();
    const refNotes = useRef();
    const refNotesError = useRef();
    const refBatchFreq = useRef();
    const refEstimate = useRef();
    const refEstimateError = useRef();
    const refHoldDate = useRef();
    const refLiveImage = useRef();
    const refShortDesc = useRef();
    const refCatalog = useRef();
    const refProduct = useRef();
    const linkCatalog = useRef();
    const linkProduct = useRef();
    const refPreview = useRef();
    const refJiraNotes = useRef();
    const refJiraNote = useRef();
    //const refBillPrice = useRef();
    //const refBillPriceError = useRef();
    const refNewUserGroup = useRef();
    const refNewUserGroupError = useRef();
    const refNewCategory = useRef();
    const refNewCategoryError = useRef();
    const refOwnerSelect = useRef();
    

    //Overlay References
    const overlay = useRef();
    const loadingOverlay = useRef();
    const popup = useRef();
    const modalHeading = useRef();
    const modalContent = useRef();
    const modalClose = useRef();
    const popupConfirm = useRef();
    const popupConfirmHeading = useRef();
    const popupConfirmContent = useRef();
    const popupConfirmClose = useRef();
        
    const [state, setState] = useState(
        {
          action:'',
          image:'none',
          //supplied_image: '',
          partnum:'',
          append_partnum: 'yes',
          name:'',
          short_desc: '',
          description:'',
          display_price: '',
          uom:'',
          display_uom: 'yes',
          orderable_qty:'',
          re_order_point:'',
          user_groups:'',
          category:'',
          arrival_date:'',
          expected_qty:'',
          batch_freq:'',
          item_type: 'other',
          approval_required:'no',
          international:'no',
          effective_date:'',
          next_day: '',
          client_notes:'',
          vc_product_id:'',
          owner:'',
          job_number:'',
          estimate_number:'',
          credit_price:'',
          customer_number:'',
          hold_date: 'no',
          new_category: '',
          new_user_group: '',
          bill_price: ''
        }
    );

    // Parse Jira Paragraph
    async function parseParagraph(array){
        let html = '';
        for(let a=0;a<array.length;a++){
            // Plain Text
            if(array[a].type === 'text' && !array[a].marks){
                html += array[a].text;
            }

            // Card Link
            if(array[a].type === 'inlineCard'){
                html += '<a href="' + array[a].attrs.url + '" target="_blank">'
                html += array[a].attrs.url;
                html += '</a>'
            }

            // Hard Line Breaks
            if(array[a].type === 'hardBreak'){
                html += '<br/>'
            }

            // Markup Text (hyperlink, strong, etc)
            if(array[a].type === 'text' && array[a].marks){
                // Place start tags
                for(let b=0;b<array[a].marks.length;b++){
                    // Hyperlink
                    if(array[a].marks[b].type === 'link'){
                        html += "<a href='" + array[a].marks[b].attrs.href + "' target='_blank'>"
                    }

                    // Strong
                    if(array[a].marks[b].type === 'strong'){
                        html += '<strong>'
                    }

                    // Italic
                    if(array[a].marks[b].type === 'em'){
                        html += '<em>'
                    }

                    // Colored Text
                    if(array[a].marks[b].type === 'textColor'){
                        html += "<span style='color:" + array[a].marks[b].attrs.color + "'>"
                    }
                }
                
                html += array[a].text

                // Place end tags
                for(let b=0;b<array[a].marks.length;b++){
                    // Hyperlink
                    if(array[a].marks[b].type === 'link'){
                        html += '</a>'
                    }

                    // Strong
                    if(array[a].marks[b].type === 'strong'){
                        html += '</strong>'
                    }

                    // Italic
                    if(array[a].marks[b].type === 'em'){
                        html += '</em>'
                    }

                    // Colored Text
                    if(array[a].marks[b].type === 'textColor'){
                        html += '</span>'
                    }
                }

            }
        }
        
        //html += '</p>';
        return html;
    }
        
    // Change handler for text, date, select, and textarea
    const handleChange = async (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));


        if(name === 'customer_number'){
            refJiraNote.current.innerHTML = '';
            refJiraNotes.current.style.display = 'none';

            let client = await clients.filter(item => item.customer_number == value)
            if(client && client.length > 0){
                // Owner Textbox
                if(client[0].owner !== null){
                    let split = client[0].owner.split(',')
                    if(split.length === 1){
                        refOwner.current.style.display = 'block';
                        setState(prevState => ({
                            ...prevState,
                            ['owner']: split[0]
                        }));
                    }else{
                        refOwner.current.style.display = 'none'
                        if(refOwnerSelect.current) refOwnerSelect.current.selectedIndex = 0;
                        console.log('re-selecting')
                        setState(prevState => ({
                            ...prevState,
                            ['owner']: ''
                        }));
                    }
                }else{
                    refOwner.current.style.display = 'none';
                    setState(prevState => ({
                        ...prevState,
                        ['owner']: ''
                    }));
                }
                // End of Owner
            }
        }

        if(name === 'owner_select'){
            if(e.target.value.indexOf('Select an') == -1){
                if(e.target.value === 'Other'){
                    refOwner.current.style.display = 'block';
                    setState(prevState => ({
                        ...prevState,
                        ['owner']: ''
                    }));
                }else{
                    refOwner.current.style.display = 'none';
                    setState(prevState => ({
                        ...prevState,
                        ['owner']: e.target.value
                    }));
                }
            }else{
                setState(prevState => ({
                    ...prevState,
                    ['owner']: ''
                }));
                refOwner.current.style.display = 'none';
            }
        }

             
        if(name === 'description'){
            refShortDesc.current.innerHTML = value;
        }

        if(name === "effective_date"){
            refDateCharge.current.classList.remove("show");
            // Next Day Logic
            let nextDay = false;
            const todaysDate = new Date();
            todaysDate.setHours(0,0,0,0);
            
            // Grab entered effective date at midnight
            const eDate = new Date(value + ' 00:00:00');

            // Add 1 business day to today's date
            const newDate = new Date(addBusinessDays(todaysDate, 1));

            // Date is 1 business day in the future
            if(eDate.getTime() === newDate.getTime()){
                refDateCharge.current.classList.add("show");
            } 
        }

        if(name === 'image'){
            if(refAttach.current.checked){
                refImageDetails.current.innerHTML = '';
                if(refImage.current.files && refImage.current.files.length > 0){
                    let str = refImage.current.files.length;
                    str += ' image';
                    if(refImage.current.files.length > 1) str += 's';
                    str += ' (from upload)';
                    refImageDetails.current.innerHTML = str; 
                }else{
                    if(copiedImage != '' && copiedImage != null){
                        let split = copiedImage.split(',');
                        let str = split.length + ' image';
                        if(split.length > 1) str += 's';
                        str += ' (from copied product)'
                        refImageDetails.current.innerHTML = str;
                    }
                }
            }
            if(refLahlouh.current.checked) refImageDetails.current.innerHTML = '';
            if(refNoImage.current.checked) refImageDetails.current.innerHTML = '';
        }

        // New Category Show/Hide
        // if(refCategory.current.value !== ''){
        //     refNewCategory.current.classList.add('show')
        // }else{
        //     refNewCategoryError.current.style.display = 'none';
        //     refNewCategory.current.classList.remove('show')
        //     refNewCategory.current.classList.remove('show-with-error')
            
        //     setState(prevState => ({
        //         ...prevState,
        //         new_category: ''
        //     }));
        // }

        // New User Group Show/Hide
        // if(refUserGroup.current.value !== ''){
        //     refNewUserGroup.current.classList.add('show')
        // }else{
        //     refNewUserGroupError.current.style.display = 'none';
        //     refNewUserGroup.current.classList.remove('show')
        //     refNewUserGroup.current.classList.remove('show-with-error')
            
        //     setState(prevState => ({
        //         ...prevState,
        //         new_user_group: ''
        //     }));
        // }
    };
    // End of handleChange

    // Confirm Modal
    async function confirmOverlay(){
        let text = '<div class="mt10 center">Would you like to enter another request?</div>';

        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
       
        popupConfirmContent.current.innerHTML = text;
        overlay.current.className = 'show';
        popupConfirm.current.className = 'show';
    }

    function previewOverlay(){
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';

        //popupConfirmContent.current.innerHTML = text;
        overlay.current.className = 'show';
        refPreview.current.classList.add("show");
    }

    // Form Submit & Validation
    async function handleSubmit(){
        let shouldSubmit = true;
        let nextDay = false;

        refAction.current.classList.remove('error-outline');
        refActionError.current.style.display='none';
        refName.current.classList.remove('error-outline');
        refNameError.current.style.display='none';
        refPartNum.current.classList.remove('error-outline');
        refPartNumError.current.style.display='none';
        refDate.current.classList.remove('error-outline');
        refDateError.current.style.display='none';
        refImageError.current.style.display = 'none';
        refDisplayPrice.current.classList.remove('error-outline');
        refDisplayPriceError.current.style.display='none';
        refErrors.current.classList.remove('show');
        if(refUOM.current) refUOM.current.classList.remove('error-outline');
        if(refUOMError.current) refUOMError.current.style.display='none';
        if(refOrderQty.current) refOrderQty.current.classList.remove('error-outline');
        if(refOrderQtyError.current) refOrderQtyError.current.style.display='none';
        if(refUserGroup.current) refUserGroup.current.classList.remove('error-outline');
        if(refUserGroupError.current) refUserGroupError.current.style.display='none';
        if(refReOrderPoint.current) refReOrderPoint.current.classList.remove('error-outline');
        if(refReOrderPointError.current) refReOrderPointError.current.style.display='none';
        if(refVeraCore.current) refVeraCore.current.classList.remove('error-outline');
        if(refVeraCoreError.current) refVeraCoreError.current.style.display='none';
        if(refCategory.current) refCategory.current.classList.remove('error-outline');
        if(refCategoryError.current) refCategoryError.current.style.display='none';
        if(refArrivalDate.current) refArrivalDate.current.classList.remove('error-outline');
        if(refArrivalDateError.current) refArrivalDateError.current.style.display='none';
        if(refExpectedQty.current) refExpectedQty.current.classList.remove('error-outline');
        if(refExpectedQtyError.current) refExpectedQtyError.current.style.display='none';
        //if(refNewCategoryError.current) refNewCategoryError.current.style.display = 'none';
        //if(refNewCategory.current) refNewCategory.current.classList.remove('show-with-error')
        //if(refNewUserGroupError.current) refNewUserGroupError.current.style.display = 'none';
        //if(refNewUserGroup.current) refNewUserGroup.current.classList.remove('show-with-error')
        
        if(user.role === 'admin' || user.role === 'sales'){
            refPortal.current.classList.remove('error-outline');
            refPortalError.current.style.display='none';
            refOwner.current.classList.remove('error-outline');
            refOwnerError.current.style.display='none';
            refEstimate.current.classList.remove('error-outline');
            refEstimateError.current.style.display='none';
            refCreditPrice.current.classList.remove('error-outline');
            refCreditPriceError.current.style.display='none';
            //refBillPrice.current.classList.remove('error-outline');
            //refBillPriceError.current.style.display='none';
        }
        if(!refNextDay.current.checked) refDateCharge.current.style.display = 'none';

        // Action Validation
        if(state.action === '' || state.action.indexOf('Select') !== -1){
            refAction.current.classList.add('error-outline');
            refActionError.current.style.display='block';
            shouldSubmit = false;
        }

        // Image Validation - Correct Type (when attached)
        if(refAttach.current.checked){
            if(refImage.current.files[0]){
                let type;
                for(let a=0;a<refImage.current.files.length;a++){
                    type = refImage.current.files[a].type;
                    if(type.indexOf('jpeg') === -1 && type.indexOf('png') === -1){
                        refImageError.current.innerHTML = 'Please upload images in JPG or PNG format.'
                        refImageError.current.style.display='block';
                        shouldSubmit = false;
                        break;
                    }
                }
                if(refImage.current.files.length > 5){
                    refImageError.current.innerHTML = 'Please upload a maximum of 5 files.'
                    refImageError.current.style.display='block';
                    shouldSubmit = false;
                }
            }else{
                if(!copiedProduct){
                    refImageError.current.innerHTML = 'Please upload at least one image.'
                    refImageError.current.style.display='block';
                    shouldSubmit = false;
                }
            }
        }

        // Part Number Validation
        if(state.partnum === ''){
            refPartNumError.current.innerHTML = 'Please enter a part number.';
            refPartNum.current.classList.add('error-outline');
            refPartNumError.current.style.display='block';
            shouldSubmit = false;
        }else{
            let dupes = requests.filter(item => item.partnum === refPartNum.current.value && (item.request_status === 'Received' || item.request_status === 'Fix' || item.request_status === 'In Progress'));
            if(dupes.length > 0){
                infoModal("Duplicate", "This part number is already associated with an open request")    
                refPartNumError.current.innerHTML = 'This part number is already associated with an open request';
                refPartNum.current.classList.add('error-outline');
                refPartNumError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Name Validation
        if(state.name === ''){
            refName.current.classList.add('error-outline');
            refNameError.current.style.display='block';
            shouldSubmit = false;
        }

        // Add Request Required Fields
        if(state.action.indexOf('Add') !== -1){
            // UOM Validation
            if(state.uom === ''){
                refUOM.current.classList.add('error-outline');
                refUOMError.current.style.display='block';
                shouldSubmit = false;
            }
            
            // Orderable Qty Validation
            if(state.orderable_qty === ''){
                refOrderQty.current.classList.add('error-outline');
                refOrderQtyError.current.style.display='block';
                shouldSubmit = false;
            }

            // User Group Validation
            if(state.user_groups === ''){
                refUserGroup.current.classList.add('error-outline');
                refUserGroupError.current.style.display='block';
                shouldSubmit = false;
            }

            // Re-Order Point Validation
            if(state.re_order_point === ''){
                refReOrderPoint.current.classList.add('error-outline');
                refReOrderPointError.current.style.display='block';
                shouldSubmit = false;
            }

            // Category Validation
            if(state.category === ''){
                refCategory.current.classList.add('error-outline');
                refCategoryError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // New Product Category Validation
        // if(state.category != '' && state.new_category === ''){
        //     refNewCategoryError.current.style.display = 'block';
        //     refNewCategory.current.classList.remove('show')
        //     refNewCategory.current.classList.add('show-with-error')
        //     shouldSubmit = false;
        // }

        // New User Group Validation
        // if(state.user_groups !== '' && state.new_user_group === ''){
        //     refNewUserGroupError.current.style.display = 'block';
        //     refNewUserGroup.current.classList.remove('show')
        //     refNewUserGroup.current.classList.add('show-with-error')
        //     shouldSubmit = false;
        // }

        // Expected Arrival Date Validation
        if(state.action.indexOf('Add') !== -1 && user.role !== 'external'){
            if(state.arrival_date === ''){
                refArrivalDate.current.classList.add('error-outline');
                refArrivalDateError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Expected Qty Validation
        if(state.action.indexOf('Add') !== -1 && user.role !== "external"){
            if(state.expected_qty === ''){
                refExpectedQty.current.classList.add('error-outline');
                refExpectedQtyError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Effective Date Validation
        if(state.effective_date === '' && user.role !== "external"){
            refDateError.current.innerHTML = 'Please enter an effective date.';
            refDate.current.classList.add('error-outline');
            refDateError.current.style.display='block';
            shouldSubmit = false;
        }

        if(state.effective_date !== '' && user.role !== "external"){
            // No Weekends
            let dayNum = new Date(refDate.current.value).getUTCDay();
            if(dayNum === 0 || dayNum === 6){
                refDateError.current.innerHTML = 'Effective date cannot be set to a weekend.';
                refNextDay.current.checked = false;
                refDateCharge.current.style.display='none';
                refDate.current.classList.add('error-outline');
                refDateError.current.style.display='block';
                shouldSubmit = false;
            }

            // Grab todays date and set hours to exactly midnight
            const todaysDate = new Date();
            todaysDate.setHours(0,0,0,0);

            // Grab entered effective date at midnight
            const eDate = new Date(state.effective_date + ' 00:00:00');

            // Add 1 business day to today's date
            const newDate = new Date(addBusinessDays(todaysDate, 1));
            
            // Date is today or earlier
            if(eDate.getTime() < newDate.getTime()){
                refDateError.current.innerHTML = 'Please choose a date at least 1 business day in the future.';
                refNextDay.current.checked = false;
                refDateCharge.current.style.display='none';
                refDate.current.classList.add('error-outline');
                refDateError.current.style.display='block';
                shouldSubmit = false;
            }

            // Date is only 1 business day in the future
            // Add next day messaging
            if(eDate.getTime() === newDate.getTime()){
                nextDay = true;
                if(!refNextDay.current.checked){
                    refDate.current.classList.add('error-outline');
                    refDateCharge.current.style.display='flex';
                    shouldSubmit = false;
                }
            }
        }

        // Notes Validation
        if(state.action.indexOf('Change') !== -1){
            if(state.client_notes === ''){
                //refNotesError.current.innerHTML = 'Please enter an effective date.';
                refNotes.current.classList.add('error-outline');
                refNotesError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Display Price Validation
        if(state.display_price != ''){
            let regex = /^\d+(\.\d{1,2})?$/;
            if(!regex.test(state.display_price)){
                refDisplayPrice.current.classList.add('error-outline');
                refDisplayPriceError.current.innerHTML = 'Display price must be numeric.';
                refDisplayPriceError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Admin / Sales Validations        
        if(user.role === 'admin' || user.role === 'sales'){
            // Portal Validation (for Admin / Sales)
            if(refPortal.current.value.indexOf('Select an') != -1){
                refPortal.current.classList.add('error-outline');
                refPortalError.current.style.display='block';
                shouldSubmit = false;
            }else{
                // Grab Company Name from Clients Table
                let requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                let response = await fetch('https://picform.lahlouhonline.com/api/client/' + state.customer_number, requestOptions)
                let data = await response.json();
                if(!data.error){
                    companyName = data.name;
                }
            }

            // VeraCore Owner
            if(refOwner.current.value === '' && state.action.indexOf('Add') !== -1){
                refOwnerError.current.innerHTML = 'Please enter the VeraCore owner.';
                refOwner.current.classList.add('error-outline');
                refOwnerError.current.style.display='block';
                shouldSubmit = false;
            }

            // VeraCore Owner Selection
            if(state.action.indexOf('Add') !== -1 && refOwnerSelect.current && refOwnerSelect.current.value === 'Other' && refOwner.current.value === ''){
                refOwnerError.current.innerHTML = 'Please enter the VeraCore owner.';
                refOwner.current.classList.add('error-outline');
                refOwnerError.current.style.display='block';
                shouldSubmit = false;
            }

            // VeraCore Product ID
            if(state.vc_product_id === ''){
                refVeraCoreError.current.innerHTML = 'Please enter the VeraCore Product ID.';
                refVeraCore.current.classList.add('error-outline');
                refVeraCoreError.current.style.display='block';
                shouldSubmit = false;
            }else{
                // VeraCore Product ID RegEx
                const regex = /^[0-9A-Za-z-]+$/
                if(!state.vc_product_id.match(regex)){
                    refVeraCoreError.current.innerHTML = 'Only numbers, letters and dashes are allowed in the part number.';
                    refVeraCore.current.classList.add('error-outline');
                    refVeraCoreError.current.style.display='block';
                    shouldSubmit = false;
                }
            }

            // Credit Price
            if(state.credit_price !== ''){
                let regex = /^\d+(\.\d{1,2})?$/;
                if(!regex.test(state.credit_price)){
                    refCreditPriceError.current.innerHTML = 'Credit price must be a numeric entry.';
                    refCreditPrice.current.classList.add('error-outline');
                    refCreditPriceError.current.style.display='block';
                    shouldSubmit = false;
                }
            }
            
            //Billing Price
            // if(state.bill_price === ''){
            //     refBillPriceError.current.innerHTML = 'Please enter a billing price.';
            //     refBillPrice.current.classList.add('error-outline');
            //     refBillPriceError.current.style.display='block';
            //     shouldSubmit = false;
            // }else{
            //     let regex = /^\d+(\.\d{1,2})?$/;
            //     if(!regex.test(state.bill_price)){
            //         refBillPriceError.current.innerHTML = 'Billing price must be a numeric entry.';
            //         refBillPrice.current.classList.add('error-outline');
            //         refBillPriceError.current.style.display='block';
            //         shouldSubmit = false;
            //     }
            // }


            // POD Validation (one thing turns on another)
            if(refBatchFreq.current.options[0].selected || refBatchFreq.current.options[1].selected){
                
            }else{
                
                if(refEstimate.current.value === ''){
                    refEstimate.current.classList.add('error-outline');
                    refEstimateError.current.style.display='block';
                    shouldSubmit = false;
                }
            }
            
            
        // Add Company Name for Teams Message when role = External
        }else{
            let requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' }
            };

            let response = await fetch('https://picform.lahlouhonline.com/api/client/' + user.customer_number, requestOptions)
            let data = await response.json();
            if(!data.error){
                companyName = data.name;
            }else{
                companyName = 'Unknown';
            }
        }

        // Copied Product Form Validation
        if(copiedProduct){
            // Part Number Validation
            if(state.partnum === original.partnum){
                refPartNumError.current.innerHTML = 'Part number must be different on a copied product.';
                refPartNum.current.classList.add('error-outline');
                refPartNumError.current.style.display='block';
                shouldSubmit = false;
            }

            // VeraCore Part Number Validation
            if(user.role === 'admin' || user.role === 'sales'){
                if(state.vc_product_id != '' && state.vc_product_id === original.vc_product_id){
                    refVeraCoreError.current.innerHTML = 'VeraCore Product ID must be different on a copied product.'    
                    refVeraCore.current.classList.add('error-outline');
                    refVeraCoreError.current.style.display='block';
                    shouldSubmit = false;
                      
                }
            }

            // Name Validation
            if(state.name === original.name){
                refNameError.current.innerHTML = 'Title must be different on a copied product.'
                refName.current.classList.add('error-outline');
                refNameError.current.style.display='block';
                shouldSubmit = false;
            }

            //shouldSubmit = false;
        }

        // Group Request Code
        if(shouldSubmit && confirm === null){
            confirmOverlay();
            return;
        }

        // Passed all form validation, submit to Teams
        // Group Request Version
        if(shouldSubmit && confirm != null){
            // Show overlay
            showLoading();
            let response;
            let data;
            
            // Create request object with the default fields
            const requestObj = {
                action: state.action,
                request_status: "Received",
                partnum: state.partnum,
                name: state.name.trim(),
                effective_date: state.effective_date,
                submitter_email: user.username,
                group_request: ""
            }

            if(user.role === 'external'){
                requestObj.request_status = 'In Review';
            }

            if(state.image) requestObj.image = state.image
            if(refLahlouh.current){
                if(refLahlouh.current.checked){
                    //requestObj.supplied_image = "yes"
                    //requestObj.image = "";
                    requestObj.image = "lahlouh";
                } 
            }
            if(refNoImage.current){
                if(refNoImage.current.checked){
                    //requestObj.image = "";
                    requestObj.image = "none";
                } 
            }
            
            if(state.short_desc) requestObj.short_desc = state.short_desc
            if(state.description) requestObj.description = state.description
            if(state.uom) requestObj.uom = state.uom
            if(state.display_uom) requestObj.display_uom = state.display_uom
            if(state.orderable_qty) requestObj.orderable_qty = state.orderable_qty
            if(state.re_order_point) requestObj.re_order_point = state.re_order_point
            if(state.category) requestObj.category = state.category
            if(state.user_groups) requestObj.user_groups = state.user_groups
            if(state.arrival_date) requestObj.arrival_date = state.arrival_date
            if(state.expected_qty) requestObj.expected_qty = state.expected_qty
            if(state.batch_freq) requestObj.batch_freq = state.batch_freq
            if(state.item_type) requestObj.item_type = state.item_type
            if(state.approval_required) requestObj.approval_required = state.approval_required
            if(state.append_partnum) requestObj.append_partnum = state.append_partnum
            if(state.display_price) requestObj.display_price = state.display_price
            if(state.credit_price) requestObj.credit_price = state.credit_price
            if(state.international) requestObj.international = state.international
            if(refNextDay.current){
                if(refNextDay.current.checked && nextDay) requestObj.next_day = "yes";
            }
            if(refHoldDate.current){if(refHoldDate.current.checked) requestObj.hold_date = "yes";}
            if(state.client_notes) requestObj.client_notes = state.client_notes
            if(state.vc_product_id) requestObj.vc_product_id = state.vc_product_id
            if(state.owner) requestObj.owner = state.owner
            if(state.job_number) requestObj.job_number = state.job_number
            if(state.estimate_number) requestObj.estimate_number = state.estimate_number
            if(state.customer_number){
                requestObj.customer_number = state.customer_number
            }else{
                requestObj.customer_number = user.customer_number
            }

            // Group Request Code
            if(confirm === true || pending) requestObj.request_status = 'Pending'
            
            // Submit Image
            
            if(refAttach.current.checked && refImage.current && refImage.current.files[0]){
                let imageFilename = null
                let formData = new FormData()
                let requestOptions;

                for(let a=0;a<refImage.current.files.length;a++){
                    formData = new FormData();
                    formData.append('image', refImage.current.files[a])
                    requestOptions = {
                        method: 'POST',
                        credentials: 'include',
                        redirect: 'manual',
                        body: formData,
                    };
                    
                    response = await fetch('https://picform.lahlouhonline.com/api/image', requestOptions)
                    
                    if(response.status === 403){
                        hideLoading();
                        infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
                        return false;
                    }else{
                        data = await response.json()
                        if(data.filename){
                            if(a===0){
                                imageFilename = data.filename
                            }else{
                                imageFilename += ',' + data.filename;
                            }
                        }
                    }
                }
                requestObj.image = imageFilename;
            }

            if(refAttach.current.checked && copiedImage != ''){
                requestObj.image = copiedImage;
            }

            // Submit to PICDB
            let requestOptions = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestObj)
            };
            
            response = await fetch('https://picform.lahlouhonline.com/api/requests', requestOptions)
            
            if(response.status === 403){
                hideLoading();
                infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
            }else{
                data = await response.json()
                if(data.name){
                    // Teams Message
                    const teamsObj =
                    {
                        "@context": "https://schema.org/extensions",
                        "@type": "MessageCard",
                        "themeColor": "0076D7",
                        "summary": "Summary",
                        "sections": [
                            {
                                "activityTitle": "Portal Item Change Request",
                                "facts": [
                                    {
                                        "name": "Type:",
                                        "value": "New request"
                                    },
                                    {
                                        "name": "Action:",
                                        "value": state.action
                                    },
                                    {
                                        "name": "Company:",
                                        "value": companyName
                                    },
                                    {
                                        "name": "Submitted By:",
                                        "value": requestObj.submitter_email
                                    },
                                    {
                                        "name": "Role:",
                                        "value": user.role
                                    },
                                    {
                                        "name": "Effective Date:",
                                        "value": formatDatePicker(new Date(state.effective_date))
                                    }
                                ],
                                "text": "https://picform.lahlouhonline.com/request?id=" + data.id
                            }
                        ]
                    }

                    // Send to Teams
                    requestOptions = {
                        method: 'POST',
                        credentials: 'include',
                        //mode: 'no-cors',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(teamsObj)
                    };
                
                    // Send Teams Message
                    // Not for external clients / test portal / pending
                    if(user.role !== 'external' && companyName.indexOf('Test Portal') === -1 && requestObj.request_status !== "Pending"){
                        fetch('https://picform.lahlouhonline.com/api/teams/' + data.id, requestOptions)
                    }
                    
                    hideLoading();
                }
            }
        }

        if(shouldSubmit){
            if(confirm){
                navigate('/')
                return;
            } 
            if(pending){
                infoModal("Pending Requests", "You have pending requests. Redirecting to the 'Requests' page.")    
                setTimeout(() => {
                    closePopup();
                    navigate('/requests')
                    return;
                }, 2850);
            }else{
                closePopup();
                navigate('/thankyou', { state: { type: 'request' } });
                return;
            }
        }else{
            //if(refErrors.current) refErrors.current.style.display = 'block';
            if(refErrors.current) refErrors.current.classList.add('show');
        }
    }

    // Informational Modal
    function infoModal(heading, content, duration = null){
        // Duration is in ms
        modalHeading.current.innerHTML = heading;
        modalContent.current.innerHTML = content;
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        overlay.current.className = 'show';
        popup.current.className = 'show';
        if(duration && duration > 0){
           setTimeout(() => {
             document.body.style.overflow = "auto";
             document.body.style.marginRight = '0';
             overlay.current.className = '';
             popup.current.className = '';
           }, duration)
        }
    }

    // Modal functionality
    function showModal(e){
        e.preventDefault();

        modalHeading.current.innerHTML = e.target.innerHTML;
        modalContent.current.innerHTML = e.target.title.replace(/(?:\r\n|\r|\n)/g, '<p>');
        
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';

        overlay.current.className = 'show';
        popup.current.className = 'show';
    }

    function showLoading(){
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        if(loadingOverlay.current) loadingOverlay.current.className = 'show';
    }

    function hideLoading(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(loadingOverlay.current) loadingOverlay.current.className = '';
    }

    function closePopup(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(overlay.current) overlay.current.className = '';
        if(popup.current) popup.current.className = '';
        if(popupConfirm.current) popupConfirm.current.className = '';
        if(refPreview.current) refPreview.current.classList.remove("show");
    }
    
    // Close popup by clicking anywhere outside of it
    window.onclick = function(event) {
        if (event.target.id === 'overlay') {
            document.body.style.overflow = "auto";
            document.body.style.marginRight = '0';
            if(overlay.current) overlay.current.className = '';
            if(popup.current) popup.current.className = '';
            if(popupConfirm.current) popupConfirm.current.className = '';
            if(refPreview.current) refPreview.current.classList.remove("show");
        }
    }

    // Back to top scroll function
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "block";
            }
        } else {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "none";
            }
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    function addBusinessDays(date, add){
        for (let i = 1; i <= add; i++) {
            date.setDate(date.getDate() + 1);
            if (date.getDay() === 6) {
                date.setDate(date.getDate() + 2);
            }
            else if (date.getDay() === 0) {
                date.setDate(date.getDate() + 1);
            }
        }

        return date;
    }

    function setDropdown(e){
        let value = e.target.parentNode.getAttribute('value')
        const items = refPortal.current.options;

        for(let a=0;a<items.length;a++){
            if(items[a].value === value){
                refPortal.current.dispatchEvent(new Event('change'));
                setState(prevState => ({
                    ...prevState,
                    ['customer_number']: value
                }));
                
                break;
            }
        }
    }

    const displayJiraNotes = async () => {
        if(clients && clients.length > 0){
            let client = await clients.filter(item => item.customer_number == refPortal.current.value)

            if(client.length > 0){
                // Jira Notes
                let requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };
            
                let response = await fetch('https://picform.lahlouhonline.com/api/jira/' + client[0].epic, requestOptions)
                let data = await response.json();
                if(!data.error){
                    let newStr = '';
                    if(data.fields.description){
                        //Array of Jira content objects from the description field
                        let contentArray = data.fields.description.content;
                        let subContentArray = [];
                        let tableRows, tableRow, tableCell;
                        let listItems, listItem;
                        let heading, headingContent;

                        for(let a=0;a<contentArray.length;a++){
                            subContentArray.length = 0;
                            // New Paragraph Content
                            if(contentArray[a].type === 'paragraph'){
                                subContentArray = contentArray[a].content;
                                newStr += '<p>';
                                newStr += await parseParagraph(subContentArray)
                                newStr += '</p>'
                            }

                            // Heading Content
                            if(contentArray[a].type === 'heading'){
                                heading = 'h' + contentArray[a].attrs.level
                                subContentArray = contentArray[a].content;
                                headingContent = await parseParagraph(subContentArray)
                                newStr += '<' + heading + '>' + headingContent + '</' + heading + '>'
                            }
                            
                            // Horizontal Rule
                            if(contentArray[a].type === 'rule'){
                                newStr += '<div class="jira-divider"></div>';
                            }
                
                            // Bullet List Items
                            if(contentArray[a].type === 'bulletList'){
                                listItems = contentArray[a].content;
                                newStr += '<ul>'
                                for(let b=0;b<listItems.length;b++){
                                    if(listItems[b].type === 'listItem'){
                                    // Bullet List Item
                                    listItem = listItems[b].content;
                                    for(let c=0;c<listItem.length;c++){
                                        if(listItem[c].type === 'paragraph'){
                                            newStr += '<li>'
                                            newStr += await parseParagraph(listItem[c].content)
                                            newStr += '</li>'
                                        }
                                    }
                                    }
                                }
                                newStr += '</ul>'
                            }
                
                            // Ordered List Items
                            if(contentArray[a].type === 'orderedList'){
                                listItems = contentArray[a].content;
                                newStr += '<ol>'
                                for(let b=0;b<listItems.length;b++){
                                if(listItems[b].type === 'listItem'){
                                    // Ordered List Item
                                    listItem = listItems[b].content;
                                    for(let c=0;c<listItem.length;c++){
                                        if(listItem[c].type === 'paragraph'){
                                            newStr += '<li>'
                                            newStr += await parseParagraph(listItem[c].content)
                                            newStr += '</li>'
                                        }
                                    }
                                }
                                }
                                newStr += '</ol>'
                            }
                
                            // Table
                            if(contentArray[a].type === 'table'){
                                newStr += '<table class="jira-table" cellpadding="0" cellspacing="0">'
                                tableRows = contentArray[a].content;
                                for(let b=0;b<tableRows.length;b++){
                                    if(tableRows[b].type === 'tableRow'){
                                        tableRow = tableRows[b].content;
                                        
                                        for(let c=0;c<tableRow.length;c++){
                                            if(tableRow[c].type === 'tableCell'){
                                                tableCell = tableRow[c].content;
                                                if(tableRow[c].attrs.rowspan){
                                                    newStr += '<td rowspan="' + tableRow[c].attrs.rowspan + '">'
                                                }else{
                                                    newStr += '<td>'
                                                }
                                                for(let d=0;d<tableCell.length;d++){
                                                    // Paragraph Content
                                                    if(tableCell[d].type === 'paragraph'){
                                                        newStr += '<p>';
                                                        newStr += await parseParagraph(tableCell[d].content)
                                                        newStr += '</p>';
                                                    }

                                                    // Heading Content
                                                    if(tableCell[d].type === 'heading'){
                                                        newStr += '<h' + contentArray[a].attrs.level + '>';
                                                        newStr += await parseParagraph(tableCell[d].content)
                                                        newStr += '</h' + contentArray[a].attrs.level + '>';
                                                    }
                
                                                    // Bullet List Items
                                                    if(tableCell[d].type === 'bulletList'){
                                                        listItems = tableCell[d].content;
                                                        newStr += '<ul>'
                                                        for(let e=0;e<listItems.length;e++){
                                                            if(listItems[e].type === 'listItem'){
                                                                // Bullet List Item
                                                                listItem = listItems[e].content;
                                                                for(let f=0;f<listItem.length;f++){
                                                                    if(listItem[f].type === 'paragraph'){
                                                                        newStr += '<li>'
                                                                        newStr += await parseParagraph(listItem[f].content)
                                                                        newStr += '</li>'
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        newStr += '</ul>'
                                                    }
                
                                                    // Ordered List Items
                                                    if(tableCell[d].type === 'orderedList'){
                                                        listItems = tableCell[d].content;
                                                        newStr += '<ol>'
                                                        for(let e=0;e<listItems.length;e++){
                                                        if(listItems[e].type === 'listItem'){
                                                            // Ordered List Item
                                                            listItem = listItems[e].content;
                                                            for(let f=0;f<listItem.length;f++){
                                                                if(listItem[f].type === 'paragraph'){
                                                                    newStr += '<li>'
                                                                    newStr += await parseParagraph(listItem[f].content)
                                                                    newStr += '</li>'
                                                                }
                                                            }
                                                        }
                                                        }
                                                        newStr += '</ol>'
                                                    }
                                                }
                                                newStr += '</td>'
                                            }
                                        }
                                    }
                                    newStr += '</tr>';
                                }
                                newStr += '</table>'
                            }
                            
                        }       
                    }
            
                    if(newStr.length > 20){
                        refJiraNote.current.innerHTML = newStr;
                        //refJiraNotes.current.style.display = 'block';
                    }else{
                        refJiraNote.current.innerHTML = 'No description entered for this Jira ticket'
                        //refJiraNotes.current.style.display = 'block';
                    }
                    refJiraNotes.current.style.display = 'block';
                }else{
                    console.log('Issue accessing Jira Description')
                }
                // End of Jira Notes
            }
        }
      }
      // End of Display Jira Notes

    // Display Jira Notes - When Portal Dropdown Changes
    useEffect(() => {
        displayJiraNotes();
      }, [state.customer_number])


    useEffect(() => {
        // Verify JWT Token
        if(refPortal.current) refPortal.current.onChange = handleChange;
        const verifyUser = async () => {
            const requestOptions = {
                method: 'POST',
                credentials: 'include',
            };
      
            let response = await fetch('https://picform.lahlouhonline.com/api/verify', requestOptions);
            let data = await response.json()
            if(data.username){
                let todaysDate = new Date();
                todaysDate.setHours(0,0,0,0);
                const newDate = new Date(addBusinessDays(todaysDate, 1)).toISOString().split('T')[0];
                // Datepicker - 1 business day in the future, gray out past days
                refDate.current.setAttribute('min', newDate);
                refArrivalDate.current.setAttribute('min', newDate);

                let requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                };

                let response = await fetch('https://picform.lahlouhonline.com/api/clients', requestOptions);
                let data = await response.json()

                // Pull in Portal List
                if(data){
                    // Build Portal Select Dropdown
                    let option;
                    for(let a=0;a<data.length;a++){
                        if(data[a].active === 1){
                            option = document.createElement("option");
                            option.text = data[a].name;
                            option.value = data[a].customer_number;
                            refPortal.current.add(option);
                        }
                    }

                    // Build 'My Portals' list for sales login
                    if(refPortalList.current){
                        let li, anchor;
                        let split = user.customer_number.split(',');
                        for(let a=0;a<split.length;a++){
                            for(let b=0;b<data.length;b++){
                                if(split[a] == data[b].customer_number && data[b].active === 1){
                                    li = document.createElement('li');
                                    li.value = data[b].customer_number;
                                    anchor = document.createElement('a');
                                    anchor.setAttribute('title','Set to ' + data[b].name);
                                    anchor.onclick = setDropdown;
                                    anchor.appendChild(document.createTextNode(data[b].name))
                                    li.appendChild(anchor);
                                    refPortalList.current.appendChild(li);
                                    break;
                                }
                            }
                        }
                    }
                    setClients(data)
                }
                
                // Check to see if logged in user has any pending requests
                response = await fetch('https://picform.lahlouhonline.com/api/requests', requestOptions)
                data = await response.json();
                if(data){
                    setRequests(data)
                    // Filter to only pending requests for current user
                    let myPending = data.filter(item => item.request_status === 'Pending' && item.submitter_email === user.username);
                    if(myPending && myPending.length >0){
                        //myPending.reverse();
                        setPending(true)
                        await setPendingRequests(myPending)
                    }
                }
            }else{
              await setUser(null)
            }
        }
        
        verifyUser();
        
    }, [])

    useEffect(() => {
        if(confirm === true || confirm === false) handleSubmit()
    }, [confirm]);

    async function copyRequest(e){
        //const request = await pendingRequests.filter(request => request.id === parseInt(e.target.getAttribute('data-id')))[0]
        if(e.target.value.indexOf('to copy') !== -1) return false;

        const request = await pendingRequests.filter(request => request.id === parseInt(e.target.value))[0]
        
        let obj = {
            action: request.action,
            //supplied_image:'',
            partnum: request.partnum,
            append_partnum: request.append_partnum,
            name: request.name,
            short_desc: '',
            description:'',
            display_price: '',
            uom:'',
            display_uom: request.display_uom,
            orderable_qty:'',
            re_order_point:'',
            user_groups:'',
            category:'',
            arrival_date:'',
            expected_qty:'',
            batch_freq:'',
            item_type: request.item_type,
            approval_required: request.approval_required,
            international: request.international,
            effective_date: new Date(request.effective_date).toISOString().split('T')[0],
            next_day: '',
            client_notes:'',
            vc_product_id:'',
            owner:'',
            job_number:'',
            estimate_number:'',
            credit_price:'',
            customer_number: request.customer_number,
        }
        
        // Set defaults
        refImageDetails.current.innerHTML = '';
        setCopiedImage('');
        refImage.current.value = '';

        //if(request.image != '' && request.image != null){
        if(request.image !== 'lahlouh' && request.image !== 'none'){
            obj.image = "attach"
            setCopiedImage(request.image);
            let split = request.image.split(',');
            let str = split.length + ' image';
            if(split.length > 1) str += 's';
            str += ' (from copied product)'
            refImageDetails.current.innerHTML = str;
        }

        // if(request.image === '' || request.image === null){
        //     obj.image = "none";
        // }
        
        // if(request.supplied_image != '' && request.supplied_image != null){
        //     obj.image = "lahlouh";
        //     obj.supplied_image = request.supplied_image;
        // } 

         if(request.short_desc != '' && request.short_desc != null) obj.short_desc = request.short_desc;
        if(request.description != '' && request.description != null) obj.description = request.description;
        if(request.display_price != '' && request.display_price != null) obj.display_price = request.display_price;
        if(request.uom != '' && request.uom != null) obj.uom = request.uom;
        if(request.orderable_qty != '' && request.orderable_qty != null) obj.orderable_qty = request.orderable_qty;
        if(request.re_order_point != '' && request.re_order_point != null) obj.re_order_point = request.re_order_point;
        if(request.user_groups != '' && request.user_groups != null) obj.user_groups = request.user_groups;
        if(request.category != '' && request.category != null) obj.category = request.category;
        if(request.arrival_date != '' && request.arrival_date != null) obj.arrival_date = new Date(request.arrival_date).toISOString().split('T')[0];
        if(request.expected_qty != '' && request.expected_qty != null) obj.expected_qty = request.expected_qty;
        if(request.batch_freq != '' && request.batch_freq != null) obj.batch_freq = request.batch_freq;
        if(request.next_day != '' && request.next_day != null) obj.next_day = request.next_day;
        if(request.client_notes != '' && request.client_notes != null) obj.client_notes = request.client_notes;
        if(request.vc_product_id != '' && request.vc_product_id != null) obj.vc_product_id = request.vc_product_id;
        if(request.owner != '' && request.owner != null) obj.owner = request.owner;
        if(request.job_number != '' && request.job_number != null) obj.job_number = request.job_number;
        if(request.estimate_number != '' && request.estimate_number != null) obj.estimate_number = request.estimate_number;
        if(request.credit_price != '' && request.credit_price != null) obj.credit_price = request.credit_price;

        // Highlight all fields
        let inputs = document.getElementsByTagName('input');
        let selects = document.getElementsByTagName('select');
        for(let a=0;a<inputs.length;a++){
            inputs[a].classList.add('copy-shadow')
        }
        // Highlight all selects except the product copy dropdown
        for(let a=1;a<selects.length;a++){
            selects[a].classList.add('copy-shadow');
        }

        setCopiedProduct(true);
        setOriginal(request)

        setState(prevState => ({
            ...prevState,
            ...obj
        }));

        // Remove highlight from fields after 1s
        setTimeout(() => {
            for(let a=0;a<inputs.length;a++){
                inputs[a].classList.remove('copy-shadow')
            }
            for(let a=0;a<selects.length;a++){
                selects[a].classList.remove('copy-shadow');
            }
            //refPartNum.current.classList.add('copy-shadow')
        }, 650)

    }

    function updateImageDetails(){
        if(refImage.current.files[0]){
            let str = refImage.current.files.length;
            str += ' image';
            if(refImage.current.files.length > 1) str += 's';
            refImageDetails.current.innerHTML = str + ' (from upload)';
            setCopiedImage('');

            // Set Live Preview Image
            let reader = new FileReader();
            reader.onload = function(e) {
                refLiveImage.current.setAttribute('src', e.target.result);
            }
            reader.readAsDataURL(refImage.current.files[0]);
            // End of Set Live Preview Image

            setState(prevState => ({
                ...prevState,
                image: "attach"
            }));
        }
    }

    function switchView(str){
        if(str === 'catalog'){
            refProduct.current.classList.remove("show")
            linkProduct.current.classList.remove("active");
            refCatalog.current.classList.add("show")
            linkCatalog.current.classList.add("active")
        }else{
            refCatalog.current.classList.remove("show")
            linkCatalog.current.classList.remove("active")
            refProduct.current.classList.add("show")
            linkProduct.current.classList.add("active");
        }
    }

    let owners = null;
    if(state.customer_number !== '' && state.customer_number.indexOf('Select an') == -1 && clients){
        owners = clients.filter(item => item.customer_number == state.customer_number)[0].owner.split(',')
    }

    let client = null;
    if(clients && clients.length > 0){
        client = clients.filter(item => item.customer_number == state.customer_number)
    }

    if(!user) return null
    
    return (
    <>
    <div className="main">
        <div className="main-content">
            <h1 className="mt10">Add/Change Item</h1>

            <div className="mt20 center">
                Hover or click on a heading to see additional information when available.
            </div>

            <div className="mt10 center">
                Required information is marked with an asterisk (<span className="required"></span>)
            </div>

            <div className="mt10 center">
                View our <a href="/receiving-standards" target="_blank" title="Click to view our Receiving Standards">Receiving Standards</a>
            </div>

            <div className="form-header slate-bg mt20"></div>

            <div className={user.role === 'admin' || user.role === 'sales' ? "form-top" : "form-bottom"}>
            
                {/* Quick Copy */}
                {pending && pendingRequests && pendingRequests.length > 0 && pendingRequests.filter(item => item.action.indexOf('Add') !== -1 || item.action.indexOf('Change') !== -1).length > 0 && 
                <>
                <div className="element">
                    <div className="center mt5">
                        <h3>Quick Copy<span className="gg-copy"></span></h3>
                        <select
                            ref={refSelectCopy}
                            className="mt10"
                            onChange={copyRequest}
                        >
                            <option>Select a request to copy...</option>
                            {pending && pendingRequests && pendingRequests.length > 0 && pendingRequests.map((item, index) => {
                                if(pendingRequests[index].action.indexOf('Add') !== -1 || pendingRequests[index].action.indexOf('Change') !== -1){
                                    return (
                                    <option key={item.id} value={item.id}>
                                        {item.partnum} - {item.name}
                                    </option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                </div>

                <div className="thin-divider"></div>
                </>
                }

                {/* {pending && pendingRequests && pendingRequests.length > 0 && pendingRequests.map((item, index) => {
                if(index < 3){
                    return (
                    <div key={item.id} data-id={item.id} className="copy-item" onClick={copyRequest} title="Copy request details">
                        {item.partnum} - {item.name}
                        <span className="gg-copy"></span>
                    </div>
                    )
                }})} */}

                {/* Portal Selection */}
                {user.role !== 'external' &&
                <div className="element">
                    <label className="required">Portal Selection</label>

                    {user.customer_number.split(',')[0] !== '1' && user.role === 'sales' &&
                    <div className="portal-list-wrapper padLeft8">
                        <strong>My Portals</strong>
                        <ul ref={refPortalList} className="portal-list mt5">
                        </ul>
                    </div>
                    }

                    <select 
                        name="customer_number"
                        ref={refPortal}
                        value={state.customer_number} 
                        onChange={handleChange}
                    >
                        <option>Select an option...</option>
                    </select>

                    <div ref={refPortalError} className="errorMessage">
                        Please select a portal.
                    </div>
                </div>
                }
                
                {/* Action */}
                <div className="element">
                    <label className="required">Action</label>
                    <select 
                        name="action"
                        ref={refAction}
                        value={state.action} 
                        onChange={handleChange}
                    >
                        <option>Select an option...</option>
                        <option>Add Item</option>
                        <option>Change Item</option>
                    </select>

                    <div ref={refActionError} className="errorMessage">
                        Please select an action.
                    </div>
                </div>

                {state.action === 'Change Item' && 
                <div className="element change-message">
                    <strong>Note:</strong> For change requests, it's only necessary to enter the information that has changed.
                </div>
                }

                

                {/* Product Image(s) */}
                {/* This image will be displayed on the portal. Standard default is to display front page or front panel of final image, unless otherwise specified. No PDF format */}
                <div className="small-width">
                    <label title="Any images that will be displayed on the portal. Standard default is to display the front page or front panel of the final image. For specific ordering, please name your files accordingly. No PDF format." onClick={event => showModal(event)} className="required">Product Images (Up to 5)</label>
                    
                    {/* Attach file */}
                    <label className="radio-container flex">
                        <input 
                            type="radio"
                            name="image"
                            id="image-attach"
                            //checked={state.image === 'attach'}
                            checked={state.image !== 'newimages' && state.image !== 'lahlouh' && state.image !== 'none'}
                            value="attach"
                            onChange={handleChange}
                            ref={refAttach}
                        />
                        <span className="checkmark"></span>
                        <div 
                            className="file-button"
                            onClick={() => refImage.current.click()}
                            title="Attach file(s)"
                        ><img src="./assets/images/upload2.png" />&nbsp;Choose Files</div>
                        <span ref={refImageDetails}></span>
                        <input 
                            name="attached-image"
                            ref={refImage}
                            className="mt5 hide"
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            multiple={true}
                            onChange={updateImageDetails}
                        />
                    </label>

                    {/* <div class="upload-thumbnail">
                        <table>
                            {/* <thead>
                                <th>Order</th>
                                <th>Thumbnail</th>
                                <th></th>
                            </thead> */}
                            {/* <tbody>
                                <tr>
                                    <td>&lt; &gt;</td>
                                    <td>1</td>
                                    <td><img ref={refImageOne} height="35" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                </div>

                {/* Lahlouh Supplied Image */}
                <div className="small-width">
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="image"
                            id="image-lahlouh"
                            checked={state.image === 'lahlouh'}
                            value="lahlouh"
                            onChange={handleChange}
                            ref={refLahlouh}
                        />
                        <span className="checkmark"></span>
                        Lahlouh Supplied Image
                    </label>
                </div>

                {/* No Image Needed */}
                <div className="small-width mt18">
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="image"
                            id="image-none"
                            checked={state.image === 'none'}
                            value="none"
                            onChange={handleChange}
                            ref={refNoImage}
                        />
                        <span className="checkmark"></span>
                        No Image Needed
                    </label>
                </div>

                <div ref={refImageError} className="small-width errorMessage"></div>

                {/* Part Number */}
                <div className="element">
                    <label className="required" title="Enter the client part number, which usually matches the Veracore Product ID." onClick={event => showModal(event)}>Client Part No. (SKU)</label>
                    <input 
                        name="partnum"
                        ref={refPartNum}
                        type="text"
                        value={state.partnum}
                        onChange={handleChange}
                    />

                    <div ref={refPartNumError} className="errorMessage"></div>
                </div>

                {/* Display Client Part No. (SKU)? */}
                <div className="radio-group">
                    <label title='Default is to display "Yes", if not wanted, click "No".' onClick={event => showModal(event)}>Display Client Part No. (SKU)?</label>
                    
                    {/* Append Part Number = Yes */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="append_partnum"
                            id="append-partnum-yes"
                            checked={state.append_partnum === 'yes'}
                            value="yes"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Yes
                    </label>

                    {/* Append Part Number = No */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="append_partnum"
                            id="append-partnum-no"
                            checked={state.append_partnum === 'no'}
                            value="no"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        No
                    </label>
                </div>
                
                {/* Product Title */}
                <div className="element">
                    <label className="required" title="Enter the product title you would like displayed on the portal." onClick={event => showModal(event)}>Product Title</label>
                    <input 
                        name="name"
                        ref={refName}
                        type="text"
                        min="2022-11-06"
                        value={state.name}
                        onChange={handleChange}
                    />
                    <div ref={refNameError} className="errorMessage">
                        Please enter a product title.
                    </div>
                </div>

                {/* Short Description */}
                <div className="element">
                    <label title="Optional: You can add product detail notes which will display on the Catalog Page (165 character limit)." onClick={event => showModal(event)}>
                        Product Short Description (Optional)
                    </label>
                    <div className="textarea-wrapper">
                        <textarea
                            name="short_desc"
                            className="short-desc"
                            value={state.short_desc}
                            onChange={handleChange}
                            maxLength={165}
                        />
                    </div>
                </div>

                {/* Long Description */}
                <div className="element">
                    <label title="Optional: You can add product detail notes which will display on the Product Detail Page." onClick={event => showModal(event)}>
                        Product Long Description (Optional)
                    </label>
                    <div className="textarea-wrapper">
                        <textarea
                            name="description"
                            value={state.description}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                {/* Display Price */}
                <div className="element">
                    <label title="This is an optional field to confirm the product price displayed to the end user." onClick={event => showModal(event)}>
                        Display Price (Optional)
                    </label>
                    <input 
                        name="display_price"
                        ref={refDisplayPrice}
                        type="text"
                        value={state.display_price}
                        onChange={handleChange}
                    />
                    <div ref={refDisplayPriceError} className="errorMessage">
                        
                    </div>
                </div>

                {/* Unit of Measure */}
                <div className="element">
                    <label className={state.action.indexOf('Add') !== -1 ? "required" : undefined} title="Unit of Measure (UOM) refers to the quantity that the item is warehoused in, i.e., 1/Each, Packs of 5, Packs of 10, etc." onClick={event => showModal(event)}>Unit of Measure (UOM)</label>
                    <input
                      type="text"
                      name="uom"
                      ref={refUOM}
                      value={state.uom}
                      onChange={handleChange}
                    />
                    <div ref={refUOMError} className="errorMessage">
                        Please enter a unit of measure.
                    </div>
                </div>

                {/* Display UOM? */}
                <div className="radio-group">
                    <label title='Default is to display "Yes", if not wanted, click "No".' onClick={event => showModal(event)}>Display UOM?</label>
                    
                    {/* Display UOM = Yes */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="display_uom"
                            id="show-uom-yes"
                            checked={state.display_uom === 'yes'}
                            value="yes"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Yes
                    </label>

                    {/* Display UOM = No */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="display_uom"
                            id="show-uom-no"
                            checked={state.display_uom === 'no'}
                            value="no"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        No
                    </label>
                </div>

                {/* Orderable Quantities */}
                <div className="element">
                    <label 
                        className={state.action.indexOf('Add') !== -1 ? "required tooltip" : "tooltip"}
                        data-html={"true"}
                        title='"Unlimited" - Free field entry, no minimum or maximum order qty&#013;&#010;Range - Ex: "1-50" - Free field entry, minimum 1, maximum 50 order qty&#013;&#010;Single Number - Ex: "1" - Dropdown with a single, specific orderable quantity&#013;&#010;Comma Separated - Ex: "5,10,15" - Dropdown selections with specific orderable quantities&#013;&#010;Comma Separated with pattern – Ex: "5,10,15...50" – Dropdown with selections up to a max – pattern indicated before "..."'
                        onClick={event => showModal(event)}
                    >
                        Orderable Quantities (by UOM)
                    </label>
                    
                    <input
                        type="text"
                        name="orderable_qty"
                        ref={refOrderQty}
                        value={state.orderable_qty}
                        onChange={handleChange}
                    />
                    <div ref={refOrderQtyError} className="errorMessage">
                        Please enter any orderable quantities.
                    </div>
                </div>

                {/* Re-Order Point */}
                <div className="element">
                    <label className={state.action.indexOf('Add') !== -1 ? "required" : undefined} title="Indicate the quantity on hand at which you want to receive an automated re-order reminder email. Keep in mind the time it takes to produce the reprint." onClick={event => showModal(event)}>
                    Re-Order Point (by UOM)</label>
                    <input
                        type="text"
                        name="re_order_point"
                        ref={refReOrderPoint}
                        value={state.re_order_point}
                        onChange={handleChange}
                    />
                    <div ref={refReOrderPointError} className="errorMessage">
                        Please enter the re-order point.
                    </div>
                </div>

                {/* User Group */}
                <div className="element">
                    <label 
                        className={state.action.indexOf('Add') !== -1 ? "required" : undefined} 
                        title="This refers to which division, business unit, or user group(s) have visibility to the item on the portal.  Include all groups if more than one." 
                        onClick={event => showModal(event)}
                    >
                        User Group(s)
                    </label>
                    <input
                        type="text"
                        name="user_groups"
                        ref={refUserGroup}
                        value={state.user_groups}
                        onChange={handleChange}
                    />

                    <div className="example">e.g. All, or specific user groups</div>

                    <div ref={refUserGroupError} className="errorMessage">
                        Please enter any associated user groups.
                    </div>
                </div>

                {/* New User Group? */}
                <div className="new-category hide" ref={refNewUserGroup} >
                    <label 
                        className="required"
                        title='Does this request include a new user group?' 
                        onClick={event => showModal(event)}
                    >New User Group?</label>

                    <div ref={refNewUserGroupError} className="errorMessage" style={{marginTop:'-8px',marginBottom:'8px'}}>
                        Please indicate if this is a new user group.
                    </div>
                    
                    {/* New User Group = Yes */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="new_user_group"
                            id="new-user-group-yes"
                            checked={state.new_user_group === 'yes'}
                            value="yes"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Yes
                    </label>

                    {/* New User Group = No */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="new_user_group"
                            id="new-user-group-yes"
                            checked={state.new_user_group === 'no'}
                            value="no"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        No
                    </label>
                </div>
                {/* End of New User Group */}

                {/* Product Category */}
                <div className="element">
                    <label 
                        className={state.action.indexOf('Add') !== -1 ? "required" : undefined} 
                        title="This refers to where the product is placed on the portal.&#013;&#010;Common groupings include: Brand, Product Type, etc." 
                        onClick={event => showModal(event)}>
                        Product Category
                    </label>
                    <input
                        type="text"
                        name="category"
                        ref={refCategory}
                        value={state.category}
                        onChange={handleChange}
                    />

                    {/* <div className="category-wrapper mt10">
                        <input
                            ref={refCategory1}
                            type="text"
                            name="category1"
                            placeholder="Category"
                            onChange={handleChange}
                        /> */}

                        {/* <span className="category-arrow" /> */}
                        {/* <input
                            ref={refCategory2}
                            className="hide"
                            type="text"
                            name="category2"
                            placeholder="Subcategory"
                            onChange={handleChange}
                        /> */}
                        {/* <span className="category-arrow" /> */}
                        {/* <input
                            ref={refCategory3}
                            className="hide"
                            type="text"
                            name="category3"
                            placeholder="Subcategory"
                            onChange={handleChange}
                        />
                    </div> */}
                    
                    <div className="example">e.g. Apparel &gt; Jackets</div>

                    <div ref={refCategoryError} className="errorMessage">
                        Please enter the product category.
                    </div>
                </div>

                {/* New Category? */}
                <div className="new-category hide" ref={refNewCategory} >
                    <label 
                        className="required"
                        title='Does this request include a new product category?' 
                        onClick={event => showModal(event)}
                    >New Product Category?</label>

                    <div ref={refNewCategoryError} className="errorMessage" style={{marginTop:'-8px',marginBottom:'8px'}}>
                        Please indicate if this is a new category.
                    </div>
                    
                    {/* New Category = Yes */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="new_category"
                            id="new-category-yes"
                            checked={state.new_category === 'yes'}
                            value="yes"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Yes
                    </label>

                    {/* New Category = No */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="new_category"
                            id="new-category-yes"
                            checked={state.new_category === 'no'}
                            value="no"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        No
                    </label>
                </div>
                {/* End of New Product Category */}


                {/* Expected Arrival Date */}
                <div className="element">
                    <label className={state.action.indexOf('Add') !== -1 && user.role !== "external" ? "required" : undefined} title="This is the date the items are scheduled to arrive at the Lahlouh warehouse." onClick={event => showModal(event)}>Expected Arrival Date</label>
                    <input
                          ref={refArrivalDate}
                          type="date"
                          name="arrival_date"
                          value={state.arrival_date}
                          onChange={handleChange}
                          onKeyDown={(e) => e.preventDefault()}
                    />
                    <div ref={refArrivalDateError} className="errorMessage">
                        Please enter the expected arrival date.
                    </div>
                </div>

                {/* Expected Quantity */}
                <div className="element">
                    <label className={state.action.indexOf('Add') !== -1 && user.role !== "external" ? "required" : undefined} title="Please provide expected quantity if product will be shipping to Lahlouh's warehouse." onClick={event => showModal(event)}>Expected Quantity</label>
                    <input
                      type="text"
                      name="expected_qty"
                      ref={refExpectedQty}
                      value={state.expected_qty}
                      onChange={handleChange}
                    />
                    <div ref={refExpectedQtyError} className="errorMessage">
                        Please enter the expected quantity.
                    </div>
                </div>

                {/* Batch Frequency */}
                <div className="element">
                    <label title="If item will be printed on demand (POD), please indicate if you prefer items to be printed in batches and if so, at what frequency." onClick={event => showModal(event)}>
                        Batch Frequency (POD Items)
                    </label> 
                    <select 
                        name="batch_freq"
                        ref={refBatchFreq}
                        value={state.batch_freq} 
                        onChange={handleChange}
                    >
                        <option value=''>Select an option...</option>
                        <option>No Batching</option>
                        <option>Every Monday</option>
                        <option>Every Tuesday</option>
                        <option>Every Wednesday</option>
                        <option>Every Thursday</option>
                        <option>Every Friday</option>
                        <option>Other</option>
                    </select>
                </div>
               
                {/* Item Type */}
                <div className="radio-group">
                <label title="Choose the type of item to be added, being mindful of any listed restrictions." onClick={event => showModal(event)}>Item Type</label>
                    {/* Food */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-food"
                            checked={state.item_type === 'food'}
                            value="food"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Food Items</strong> - Cannot ship internationally. Please provide expiration dates and lot numbers in special instructions.
                    </label>

                    {/* Agricultural or Sanitizer */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-ag-or-sanitizer"
                            checked={state.item_type === 'agricultural-or-sanitizer'}
                            value="agricultural-or-sanitizer"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Agricultural Items & Sanitizer</strong> - Cannot ship internationally.
                    </label>

                    {/* Contains Batteries */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-contains-batteries"
                            checked={state.item_type === 'contains-batteries'}
                            value="contains-batteries"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Items with Batteries</strong> - Subject to shipping restrictions and special labeling requirements.
                    </label>

                    {/* Face Mask */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-face-mask"
                            checked={state.item_type === 'face-mask'}
                            value="face-mask"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Face Masks</strong> - Subject to international shipping restrictions by country.
                    </label>

                    {/* Other */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="item_type"
                            id="radio-other"
                            checked={state.item_type === 'other'}
                            value="other"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        <strong>Other</strong>
                    </label>
                </div>

                <div className="thin-divider"></div>

                {/* Approvals Required? */}
                <div className="radio-group">
                    <label title="Indicate if an approval is required when this item is ordered.  Add instructions in the product notes field if needed." onClick={event => showModal(event)}>
                        Order Approvals Required?
                    </label>
                    
                    {/* Approval Required = Yes */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="approval_required"
                            id="approval-yes"
                            checked={state.approval_required === 'yes'}
                            value="yes"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Yes
                    </label>

                    {/* Approval Required = No */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="approval_required"
                            id="approval-no"
                            checked={state.approval_required === 'no'}
                            value="no"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        No
                    </label>
                </div>

                <div className="thin-divider"></div>

                {/* International Shipping? */}
                <div className="radio-group">
                    <label title="If international shipping is needed please include item value ($) & country of origin in the comments field." onClick={event => showModal(event)}>International Shipping?</label>
                    
                    {/* International Shipping = Yes */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="international"
                            id="international-yes"
                            checked={state.international === 'yes'}
                            value="yes"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Yes
                    </label>

                    {/* International Shipping = No */}
                    <label className="radio-container">
                        <input 
                            type="radio"
                            name="international"
                            id="international-no"
                            checked={state.international === 'no'}
                            value="no"
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        No
                    </label>
                </div>

                {/* Effective Date */}
                <div className="element">
                    <label className={user.role !== "external" ? "required" : undefined} title="Change could go live sooner than this date depending on the teams workload. Check the 'Hold until effective date' checkbox if needed." onClick={event => showModal(event)}>Effective Date</label>
                    <input 
                        type="date"
                        name="effective_date"
                        ref={refDate}
                        value={state.effective_date}
                        onChange={handleChange}
                        onKeyDown={(e) => e.preventDefault()}
                    />

                    <div ref={refDateError} className="errorMessage"></div>

                    <div ref={refDateCharge} className="radio-check-wrapper next-day-message">
                      <label className="checkbox-container no-highlight">
                            <input 
                                ref={refNextDay}
                                type="checkbox"
                                name="next-day-check"
                                id="next-day"
                                value={state.next_day}
                                onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                            Next day service may incur an additional charge.
                        </label>
                    </div>
                
                    {/* Hold Date */}
                    <div className="radio-check-wrapper mt10">
                      <label className="checkbox-container no-highlight">
                            <input 
                                ref={refHoldDate}
                                type="checkbox"
                                name="hold-date-check"
                                id="hold-date"
                                //value="yes"
                                //value={state.hold_date}
                                //checked={state.hold_date === 'yes'}
                                onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                            Hold until effective date
                        </label>
                    </div>
                </div>
                {/* End of Effective Date */}

                {/* Product Notes */}
                <div className="element">
                    <label className={state.action.indexOf('Change') !== -1 ? "required" : ""} title="Enter any specific actions or changes related to this product. Description, placement on the site, etc. Include any details that are needed to help aid the actions to be taken." onClick={event => showModal(event)}>
                        Additional Product Notes
                    </label>
                    <div className="textarea-wrapper">
                        <textarea
                        name="client_notes"
                        ref={refNotes}
                        value={state.client_notes}
                        onChange={handleChange}
                        />
                    </div>
                    <div ref={refNotesError} className="errorMessage">
                        Please enter any needed change notes.
                    </div>
                </div>
            </div>

            {(user.role === 'admin' || user.role === 'sales') &&
            <div className="internal-content">
              <div className="form-divider yellow-bg center">
                Lahlouh Internal
              </div>

              {/* <h2 className="center">Lahlouh Details</h2> */}
              
              <div className="admin-form-bottom">
                

                {/* VeraCore Owner */}
                <div
                    className="element"
                    style={{display:state.customer_number !== '' && state.customer_number.indexOf('Select an') == -1 ? 'block' : 'none'}}
                >
                    <label 
                        className={state.action.indexOf('Add') !== -1 ? "required" : undefined}
                        title="Enter the VeraCore owner info for this particular product." 
                        onClick={event => showModal(event)}
                    >
                        VeraCore Owner
                    </label> 
                    
                    {/* {clients && clients.filter(item => item.customer_number == state.customer_number).length > 0 && */}
                    {owners && owners.length > 1 &&
                    <select 
                        name="owner_select"
                        ref={refOwnerSelect}
                        value={state.owner_select} 
                        onChange={handleChange}
                        className="mb10"
                    >
                        <option>Select an option...</option>
                        {owners.map((item, i) => (
                            <option key={i}>{item}</option>
                        ))}
                        <option>Other</option>
                    </select>
                    }

                    <input
                      type="text"
                      ref={refOwner}
                      name="owner"
                      //className="hide"
                      value={state.owner}
                      onChange={handleChange}
                    />

                    <div ref={refOwnerError} className="errorMessage">
                        Enter the VeraCore owner.
                    </div>
                </div>
                
                {/* VeraCore Product ID */}
                <div className="element">
                    <label className="required" title="This is the product ID that is assigned by Lahlouh. Ideally, it is the same as the Client Part No. (SKU) and should be included on the physical inventory item. It is included on all carton labels." onClick={event => showModal(event)}>
                        VeraCore Product ID #
                    </label> 
                    <input
                      type="text"
                      ref={refVeraCore}
                      name="vc_product_id"
                      value={state.vc_product_id}
                      onChange={handleChange}
                    />

                    <div ref={refVeraCoreError} className="errorMessage"></div>
                </div>
                
                {/* Job Number */}
                <div className="element">
                    <label title="Only required if product is in production at the time this form is submitted." onClick={event => showModal(event)}>
                        Lahlouh Job #
                    </label> 
                    <input
                      type="text"
                      name="job_number"
                      value={state.job_number}
                      onChange={handleChange}
                    />
                </div>

                {/* PACE Template # */}
                <div className="element">
                    <label className={refBatchFreq.current && !refBatchFreq.current.options[0].selected && !refBatchFreq.current.options[1].selected ? "required" : ""} title="Only required with print-on-demand (POD) items." onClick={event => showModal(event)}>
                      PACE Template # (for POD Items)
                    </label> 
                    <input
                      type="text"
                      name="estimate_number"
                      ref={refEstimate}
                      value={state.estimate_number}
                      onChange={handleChange}
                    />
                    <div ref={refEstimateError} className="errorMessage">
                        Please enter a PACE Template #.
                    </div>
                </div>

                {/* Credit / Reimbursement Price */}
                {state.action !== 'Remove Item' && state.action !== 'Image Update' &&
                <div className="element">
                    <label title="Cost of product that will need to be reimbursed or credit given per unit." onClick={event => showModal(event)}>
                      Reimbursement or Credit Price
                    </label> 
                    <input
                      type="text"
                      name="credit_price"
                      ref={refCreditPrice}
                      value={state.credit_price}
                      onChange={handleChange}
                    />

                    {/* <div className="example">e.g. 99.99</div> */}

                    <div ref={refCreditPriceError} className="errorMessage"></div>
                </div>
                }

                <div ref={refJiraNotes} className="element jira-notes">
                    <div className="jira-divider"></div>
                    <div className="jira-heading">
                        <strong>Jira Notes</strong>
                    </div>
                    {client && client.length > 0 &&
                    <div className="mt10 center">
                        <a href={"https://lahlouh.atlassian.net/browse/" + client[0].epic} target="_blank" title="View JIRA Maintenance Ticket">
                            <button className="rounded-button">View Maintenance Jira</button>
                        </a>
                    </div>
                    }
                    <div ref={refJiraNote} className="jira-note"/>
                </div>
                
              </div>
            </div>
            }

            <div ref={refErrors} className="small-width errorMaster center">
                There are items that require your attention above
            </div>
           
            <div className="submit-save-wrapper mt20 center">
                <button className="primary-button" title="Submit" onClick={handleSubmit}>Submit</button>
                {/* <button className="secondary-button" title="Save for later" onClick={handleSubmit}>Save for later</button> */}
            </div>
        </div>

        <div ref={refPreview} className="live-preview-wrapper">
            <div className="live-preview-header">
                Live Preview
            </div>

            <div className="preview-header mt10">
                <span
                    ref={linkCatalog}
                    className='active'
                    onClick={() => switchView('catalog')}
                    >Catalog View
                </span> | 
                <span
                    ref={linkProduct}
                    onClick={() => switchView('product')}
                >
                    Product Details
                </span>
            </div>
            
            <div className="mt10 center">
                Title Length: {(state.name + ' / ' + state.partnum).length}         
            </div>

            <div ref={refCatalog} className="catalog show">
                <div className="catalog-image">
                    <img ref={refLiveImage} src="assets/images/sample-image2.png" />
                </div>
                <div className="catalog-title">
                    {state.append_partnum === 'yes' && state.partnum ? (state.name + ' / ' + state.partnum).substring(0,72) : state.name.substring(0,74)}
                    
                    {(state.name + ' / ' + state.partnum).length > 72 && " ..."}
                    
                    {state.append_partnum === 'no' && state.name.length > 72 && " ..."}
                </div>

                <div ref={refShortDesc} className="catalog-desc">
                    {state.short_desc &&  state.short_desc.substring(0,165)}
                    {state.description.length > 165 && " ..."}
                </div>

                <div className="catalog-uom">
                    {state.uom && state.uom}
                </div>

                <div className="catalog-add-to-cart">
                    <img src="assets/images/add-to-cart.png" />
                </div>
            </div>

            <div className="product-view" ref={refProduct}>
                {/* <span className="close" onClick={closePopup}>&times;</span> */}
                {/* <div className="pv-image mt10">
                    <img ref={refLiveImage} src="assets/images/sample-image2.png" />
                </div> */}
                <div className="pv-title">
                    {state.append_partnum === 'yes' && state.partnum ? (state.name + ' / ' + state.partnum) : state.name}
                    {/* {state.append_partnum === 'no' && state.name.length > 72 && " ..."} */}
                </div>

                <div className="pv-stock">
                    <img src="assets/images/in-stock.png" />
                </div>

                <div ref={refShortDesc} className="pv-desc">
                    {/* {state.description &&  state.description.substring(0,150)}
                    {state.description.length > 150 && " ..."} */}
                </div>

                <div className="pv-qty">
                    <img src="assets/images/pv-qty.png" />
                </div>

                <div className="pv-price">
                    {state.display_price && "$" + state.display_price}
                    {state.display_price && <span className="pv-currency">USD</span>}
                </div>

                {/* <div className="pv-uom">
                    {state.uom && state.uom}
                </div> */}

                {state.display_price &&
                <div className="pv-summary">
                    <div className="pv-summary-title">Summary</div>
                    <div className="pv-summary-row">
                        <div>Product Total (1 Item)</div>
                        <div>${state.display_price}</div>
                    </div>

                    <div className="pv-summary-row">
                        <div>Shipping calculated at checkout</div>
                        <div />
                    </div>

                    <div className="pv-summary-row">
                        <div>Tax</div>
                        <div>$0.00</div>
                    </div>

                    <div className="pv-summary-divider" />

                    <div className="pv-summary-row">
                        <div class="semi-bold">Total price (USD)</div>
                        <div className="pv-total">${state.display_price}</div>
                    </div>

                </div>
                }
                <div className="pv-add-to-cart">Add to Cart</div>
            </div>
        </div>
    </div>

    <div ref={refReturnTop} className="return-top" onClick={topFunction}>
      &#x25B2; Return to top
    </div>

    <div id="overlay" ref={overlay}></div>

    <div id="loading-overlay" ref={loadingOverlay}>
        <div className="deadCenter">
            <div className="lds-spinner">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
        </div>
    </div>

    <div id="popup" ref={popup}>
        <h2 id="modal-heading" className="center" ref={modalHeading}></h2>
        <span id="popup-close" ref={modalClose} className="close" onClick={closePopup}>&times;</span>
        <div id="modal-content" ref={modalContent} className="content center"></div>
    </div>

    <div id="popup-confirm" ref={popupConfirm}>
        <h2 id="modal-heading" ref={popupConfirmHeading} style={{textAlign:'center'}}>Anything Else?</h2>
        <span className="close" ref={popupConfirmClose} onClick={() => {closePopup();setConfirm(null)}}>&times;</span>
        <div id="modal-content" ref={popupConfirmContent} className="content center">
          Text changed through JS
        </div>
        <div className="mt10 center">
          <button className="primary-button smaller-button" onClick={(e) => {closePopup();overlay.current.className = 'show';setConfirm(true);}} title="Yes">Yes</button>
          <button className="secondary-button smaller-button" onClick={() => {closePopup();overlay.current.className = 'show';setConfirm(false);}} title="No">No</button>
        </div>
    </div>
    </>
  )
}
